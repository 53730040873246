import React, { useEffect, useContext, useState, Fragment, useRef } from 'react';
import axios from 'axios';
import $ from 'jquery';

import { LoginContext } from '../../hooks/context/LoginContext';

import Cookies from 'universal-cookie';

import LoadingPage from '../../componentes/ui/LoadingPage';
import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import Informes from '../../componentes/formulario/informes/Informes';
import { Alert } from '../../componentes/ui/Alert';
import { SRLWrapper } from 'simple-react-lightbox';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import MiSelect from '../../componentes/formulario/MiSelect';
import MiDataTableIncidencias from './MiDataTableIncidencias';
import Button from '../../componentes/ui/Button';
import { Modal } from 'react-bootstrap';
import FormInput from '../../componentes/formulario/FormInput';
import TextArea from '../../componentes/formulario/TextArea';
import { Spinner } from 'react-bootstrap';
import swal from 'sweetalert';
import FormRow from '../../componentes/formulario/FormRow';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import UplmPlus2 from '../../componentes/formulario/UpmlPlus2/UplmPlus2';

import { alertService } from '../../services/alertservice';

import { getTitle, getApiUrl, MiReplaceAll, getAhorayyyymmdd, getAhoraInputDateTimeLocal, encryptData, getDecodedCookie, getNumFromData } from '../../helpers/helperApp';

function Inci(props){

    const cookies = new Cookies();

    // ***** Context *****
    const { validateSesion, confApp, saveConfApp, getConfApp, centroActivo, apiKey, inciIdUsuViewer, inciMinReload } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ dataTableInci, saveDataTableInci ] = useState([]);
    const [ loadingTableInci, saveLoadingTableInci ] = useState(false);
    const [ showModalCrearInci, saveShowModalCrearInci ] = useState(false);
    const [ valModalCentro, saveValModalCentro ] = useState(0);
    const [ valModalEstado, saveValModalEstado ] = useState(0);
    const [ valModalFecha, saveValModalFecha ] = useState(getAhoraInputDateTimeLocal());
    const [ valModalDescripcion, saveValModalDescripcion ] = useState('');
    const [ valModalNotaAlCrear, saveValModalNotaAlCrear ] = useState('');
    const [ valModalAgente, saveValModalAgente ] = useState(0);
    const [ valModalPrioridad, saveValModalPrioridad ] = useState(0);
    const [ valModalTipo, saveValModalTipo ] = useState(0);
    const [ creandoRegistro, saveCreandoRegistro ] = useState(false);
    const [ showModalAddNota, saveShowModalAddNota ] = useState(false);
    const [ notas, saveNotas ] = useState('');
    const [ notaAdd, saveNotaAdd ] = useState('');
    const [ descripcion, saveDescripcion ] = useState('');
    const [ ageAsignado, saveAgeAsignado ] = useState(0);
    const [ prioridad, savePrioridad ] = useState(0);
    const [ lineaSeleccionada, saveLineaSeleccionada ] = useState();
    const [ countdown, setCountdown ] = useState(null);
    const [ dataTableHis, saveDataTableHis ] = useState([]);
    const [ loadingTableHis, saveLoadingTableHis ] = useState(false);
    const [ valHistorialCentro, saveValHistorialCentro ] = useState(0);
    const [ valHistorialAgente, saveValHistorialAgente ] = useState(0);
    const [ valHistorialTipo, saveValHistorialTipo] = useState(0);
    const [ modoEditarNota, saveModoEditarNota ] = useState(false);
    const [adjuntos, saveAdjuntos] = useState([]);
    const [adjuntosExistentes, saveAdjuntosExistentes] = useState([]);


    let intervalId;
    const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2} - .+$/;
    let currentMessage = null;
    const chatContainerRef = useRef(null);

    useEffect( () => {                
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title = getTitle(confPantalla.config.trad, confPantalla.inci.title);                    
                    if( respuesta == 1 ){
                        saveTieneNivel(true);                        
                        init();
                        
                        let frecuenciaReload = null;                        
                        inciMinReload == '' || inciMinReload == '0' ? frecuenciaReload = 1*60*1000 : frecuenciaReload = parseInt(inciMinReload)*60*1000;
                        if(getDecodedCookie('id') == inciIdUsuViewer){
                            intervalId = setInterval(() => {                         
                                let countdownTimer = 10; 
                                const countdownInterval = setInterval(() => { 
                                    setCountdown(countdownTimer); 
                                    countdownTimer--; 
                                    if (countdownTimer < 0) { 
                                        clearInterval(countdownInterval); 
                                        setCountdown(null); 
                                        loadDatosTableInci();
                                    } 
                                }, 1000);                         
                            }, frecuenciaReload);
                        }
                        
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                                
                    saveLoadedConf(true);
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })                  
        return () =>{
                document.querySelector("body").classList.remove("sidebar-open");
                clearInterval(intervalId);
        } 

    },[confPantalla])

    useEffect(() => { 
        if (showModalAddNota && chatContainerRef.current) { 
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight; 
        } 
    }, [showModalAddNota]);

    const init = async() => {       
       await loadDatosTableInci();
    }

    const loadDatosTableInci = async () => {
        saveLoadingTableInci(true);        
        const data = new FormData();      
        data.append("validacion", apiKey());
        const respuesta = await axios.post(getApiUrl(window.location.origin)+'/inci/getIncidencias', data);   
        if( typeof respuesta.data == "string"){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true}); 
            saveDataTableInci([]);
        }else{
            saveDataTableInci(respuesta.data);                  
        }

        saveLoadingTableInci(false);
    }

    const clickBtnCrear = () => {
        // let valDefectoCentro = 0;
        // // Si tenemos centro seleccionado en el select del menú lo establecemos
        // if(centroActivo != '' && centroActivo != '0'){
        //     valDefectoCentro = centroActivo;
        // }
        // // Si solo hay un centro en el select del modal lo seleccionamos por defecto                
        // if(backUpCentrosModal.length == 1){            
        //     valDefectoCentro = backUpCentrosModal[0].value;
        // }        
        saveAdjuntos([]);
        saveAdjuntosExistentes([]);
        saveModoEditarNota(false);
        saveValModalCentro(0);
        saveValModalFecha(getAhoraInputDateTimeLocal());
        saveValModalDescripcion('');
        saveValModalNotaAlCrear('');
        saveValModalAgente(0);
        saveValModalPrioridad(0);
        saveValModalTipo(0)        
        saveShowModalCrearInci(true);
    }

    const showEditNota = async(e, rowData) => {

        // Cargar adjuntos
        const data = new FormData();                                     
        data.append("validacion", apiKey()); 
        data.append("id", rowData.id);
        const respuesta = await axios.post(getApiUrl(window.location.origin)+'/lineas/getAdjLineas', data);
        if( typeof respuesta.data == "string"){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});
        }else{ 
            saveAdjuntosExistentes(respuesta.data.adjuntos);
        }

        saveAdjuntos([]);         
        saveModoEditarNota(true);
        saveValModalCentro(rowData.idcen);
        saveValModalEstado(rowData.idest);
        saveValModalFecha(rowData.fec1);
        saveValModalDescripcion(rowData.descripciondata);
        saveValModalNotaAlCrear('');
        saveValModalAgente(rowData.idage2);
        saveValModalPrioridad(rowData.prioridad);
        saveValModalTipo(rowData.tipo)        
        saveShowModalCrearInci(true);
        saveLineaSeleccionada(rowData);
    }

    const crearRegistroModal = async () => {

        saveCreandoRegistro(true);  

        // Borramos el filtro que pueda existir en los adjuntos plus ya que sino al tener registros filtrados, están con display none y no se envían por post con lo que se borrarían del registro.        
        const tablasAdjPlus = document.querySelectorAll('.adjPlus');
        for( let i = 0; i < tablasAdjPlus.length; i++){
            const tabla = $('#'+tablasAdjPlus[i].id).DataTable();
            tabla.search('').draw();
        }
        
        const data = new FormData();  
        for(let i = 0; i < adjuntos.length; i++ ){
            if( !adjuntos[i].urlAdd ){                
                data.append("fileNew**"+adjuntos[i].name, adjuntos[i].description != undefined ? adjuntos[i].description : adjuntos[i].name ); 
                data.append("fileNew**"+adjuntos[i].name+"**idtip", adjuntos[i].idtip != undefined ? adjuntos[i].idtip : 0 );
                data.append("fileNew**"+adjuntos[i].name+"**fcad", adjuntos[i].fcad != undefined ? adjuntos[i].fcad : '0000-00-00' ); 
                data.append(adjuntos[i]["id"]+"[]", adjuntos[i]);
            }
        }                                                       
        data.append("validacion", apiKey()); 
        data.append("idcen", valModalCentro);       
        data.append("fec", MiReplaceAll(valModalFecha,'T', ' ')+':00');
        data.append("descripcion", valModalDescripcion);
        data.append("idage", valModalAgente);
        data.append("idpri", valModalPrioridad);
        data.append("tipo", valModalTipo);
        data.append("valmodalnotaalcrear", valModalNotaAlCrear);                
        //const respuesta = await axios.post(getApiUrl(window.location.origin)+'/inci/createIncidencia', data);
        const respuesta = await axios.post(getApiUrl(window.location.origin)+'/inci/createIncidencia',data,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });               
        if( typeof respuesta.data == "string"){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});
        }else{ 
            await searchHis();          
            await loadDatosTableInci();
        }

        saveCreandoRegistro(false);

        saveShowModalCrearInci(false);
    }

    const guardarRegistroModal = async () => {
        saveCreandoRegistro(true);
        
         // Borramos el filtro que pueda existir en los adjuntos plus ya que sino al tener registros filtrados, están con display none y no se envían por post con lo que se borrarían del registro.
         const tablasAdjPlus = document.querySelectorAll('.adjPlus');
        for( let i = 0; i < tablasAdjPlus.length; i++){
            const tabla = $('#'+tablasAdjPlus[i].id).DataTable();
            tabla.search('').draw();
        }
        
        const data = new FormData();  
        for(let i = 0; i < adjuntos.length; i++ ){
            if( !adjuntos[i].urlAdd ){                
                data.append("fileNew**"+adjuntos[i].name, adjuntos[i].description != undefined ? adjuntos[i].description : adjuntos[i].name ); 
                data.append("fileNew**"+adjuntos[i].name+"**idtip", adjuntos[i].idtip != undefined ? adjuntos[i].idtip : 0 );
                data.append("fileNew**"+adjuntos[i].name+"**fcad", adjuntos[i].fcad != undefined ? adjuntos[i].fcad : '0000-00-00' ); 
                data.append(adjuntos[i]["id"]+"[]", adjuntos[i]);
            }
        }
        for(let i = 0; i < adjuntosExistentes.length; i++ ){
            if( !adjuntosExistentes[i].urlAdd ){                                
                data.append("fileExist**rel_lineas_adj**"+adjuntosExistentes[i].id, adjuntosExistentes[i].des);
                data.append("fileExist**rel_lineas_adj**"+adjuntosExistentes[i].id+"**idtip", adjuntosExistentes[i].idtip);
                data.append("fileExist**rel_lineas_adj**"+adjuntosExistentes[i].id+"**fcad", adjuntosExistentes[i].fcad);
            }
        }     
               
        data.append("validacion", apiKey()); 
        data.append("idlin", lineaSeleccionada.id);
        data.append("idest", valModalEstado);       
        data.append("fec", MiReplaceAll(valModalFecha,'T', ' ')+':00');
        data.append("descripcion", valModalDescripcion);
        data.append("idage", valModalAgente);
        data.append("idpri", valModalPrioridad);
        data.append("tipo", valModalTipo);
        data.append("valmodalnotaalguardar", valModalNotaAlCrear);                
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+'/inci/modifyIncidencia', data);
        const respuesta = await axios.post(getApiUrl(window.location.origin)+'/inci/modifyIncidencia',data,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });               
        if( typeof respuesta.data == "string"){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});
        }else{       
            await searchHis();    
            await loadDatosTableInci();
        }

        saveCreandoRegistro(false);

        saveShowModalCrearInci(false);
    }

    const clickBtnRecargar = async () => {
        await loadDatosTableInci();
    }

    const showAddNota = async(e, rowData) => { 

        // Cargar adjuntos
        const data = new FormData();                                     
        data.append("validacion", apiKey()); 
        data.append("id", rowData.id);
        const respuesta = await axios.post(getApiUrl(window.location.origin)+'/lineas/getAdjLineas', data);
        
        if( typeof respuesta.data == "string"){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});
        }else{             
            saveAdjuntosExistentes(respuesta.data.adjuntos);
        }

        saveAdjuntos([]);
        saveDescripcion(rowData.descripcion);       
        saveNotaAdd('');
        saveLineaSeleccionada(rowData);
        saveNotas(rowData.notas);        
        saveAgeAsignado(rowData.idage2);
        savePrioridad(rowData.prioridad)
        saveShowModalAddNota(true);
    }

    const crearNota = async () => {
        saveCreandoRegistro(true);
        
        // Borramos el filtro que pueda existir en los adjuntos plus ya que sino al tener registros filtrados, están con display none y no se envían por post con lo que se borrarían del registro.        
        const tablasAdjPlus = document.querySelectorAll('.adjPlus');
        for( let i = 0; i < tablasAdjPlus.length; i++){
            const tabla = $('#'+tablasAdjPlus[i].id).DataTable();
            tabla.search('').draw();
        }
        
        const data = new FormData();  
        for(let i = 0; i < adjuntos.length; i++ ){
            if( !adjuntos[i].urlAdd ){                
                data.append("fileNew**"+adjuntos[i].name, adjuntos[i].description != undefined ? adjuntos[i].description : adjuntos[i].name ); 
                data.append("fileNew**"+adjuntos[i].name+"**idtip", adjuntos[i].idtip != undefined ? adjuntos[i].idtip : 0 );
                data.append("fileNew**"+adjuntos[i].name+"**fcad", adjuntos[i].fcad != undefined ? adjuntos[i].fcad : '0000-00-00' ); 
                data.append(adjuntos[i]["id"]+"[]", adjuntos[i]);
            }
        }
        for(let i = 0; i < adjuntosExistentes.length; i++ ){
            if( !adjuntosExistentes[i].urlAdd ){                                
                data.append("fileExist**rel_lineas_adj**"+adjuntosExistentes[i].id, adjuntosExistentes[i].des);
                data.append("fileExist**rel_lineas_adj**"+adjuntosExistentes[i].id+"**idtip", adjuntosExistentes[i].idtip);
                data.append("fileExist**rel_lineas_adj**"+adjuntosExistentes[i].id+"**fcad", adjuntosExistentes[i].fcad);
            }
        }
        data.append("validacion", apiKey()); 
        data.append("idlin", lineaSeleccionada.id);        
        data.append("notaadd", notaAdd);
        data.append("ageasig", ageAsignado); 
        data.append("prioridad", prioridad);               
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+'/inci/createNota', data);
        const respuesta = await axios.post(getApiUrl(window.location.origin)+'/inci/createNota',data,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });              
        if( typeof respuesta.data == "string"){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});
        }else{           
            await loadDatosTableInci();
        }

        saveCreandoRegistro(false);

        saveShowModalAddNota(false);
    }

    const cerrarNotaSwal = async () => {          
        swal({
            title: "",
            text: confPantalla.inci.controles.swalcerrarnota.title,
            icon: "warning",
            buttons: {
                cancel : {text: confPantalla.inci.controles.swalcerrarnota.button.cancel.title, className:'btn btn-flat mr-1 btn-outline-danger btnSwalDelete', visible: true},
                confirm : {text: confPantalla.inci.controles.swalcerrarnota.button.confirm.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'}
            } 
        }).then(confirmacion => {
            if(confirmacion){
                cerrarNota(); 
            }
        })       
    }

    const cerrarNota = async() => {
        saveLoadingTableInci(true);

        // Guardamos y luego cerramos
        await crearNota();

        saveShowModalAddNota(false);        

        const data = new FormData();             
        data.append("validacion", apiKey()); 
        data.append("idlin", lineaSeleccionada.id);                               
        const respuesta = await axios.post(getApiUrl(window.location.origin)+'/inci/cerrarNota', data);             
        if( typeof respuesta.data == "string"){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});
        }else{
            await searchHis();              
            await loadDatosTableInci();
        } 
        
        saveLoadingTableInci(false); 
    }

    const searchHis = async () => {
        if(valHistorialCentro != 0 || valHistorialAgente != 0 || valHistorialTipo != 0){
            saveLoadingTableHis(true);               
    
            const data = new FormData();             
            data.append("validacion", apiKey()); 
            data.append("idcen", valHistorialCentro);        
            data.append("idage", valHistorialAgente);
            data.append("idtipo", valHistorialTipo);         
            const respuesta = await axios.post(getApiUrl(window.location.origin)+'/inci/searchHis', data);             
            if( typeof respuesta.data == "string"){
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});
            }else{           
                saveDataTableHis(respuesta.data);
            }
    
            saveLoadingTableHis(false);
        }
    }

    const handleSelect= (selectedItem, nameOfComponent, saveData) => {
        if( selectedItem ){
            saveData(selectedItem.value) 
        }else{
            saveData(0)
        }
    }

    const handleInput = (e, saveData) => {        
        const { name, value } = e.target;
        saveData(value)       
    }

    return(
    <SRLWrapper>
        <div className="App wrapper">
            <Alert fondo={true} />    
            {
                confPantalla !== undefined
                ?
                    <Fragment>
                        <Menusup 
                            props = { props }
                        />
                        <Sidebar 
                            props = { props }
                        />
                    </Fragment>
                :
                null
            } 
            <div className="content-wrapper">
                <div className="content-header"></div>
                <section className="content">
                {
                    !tieneNivel 
                ?
                    <PermissionRefused
                        traduction={confPantalla.config.trad.components.permissionrefused} 
                    />
                :
                    <Fragment>
                    {
                    loadedConf === true && confPantalla !== undefined
                    ?
                    <Fragment>
                        {
                            getDecodedCookie('id') != inciIdUsuViewer
                            ?
                                <div className='container-fluid mb-3'>
                                    <Informes
                                        select =     { confPantalla.inci.controles.informes.select }
                                        formulario = { {id:0} }
                                        loadReg =    { false }
                                        estadoForm=  { "" } 
                                        traduction=  { confPantalla.config.trad.components }                                
                                    />                            
                                </div>                                              
                            :
                                null
                        }                       
                        <Section titulo={ confPantalla.inci.sectioninci.titulo } icono={ confPantalla.inci.sectioninci.icono } col={ confPantalla.inci.sectioninci.col } wiki={ confPantalla.inci.sectioninci.wiki } >
                            <FormRow clases=''>
                                {
                                    getDecodedCookie('id') != inciIdUsuViewer
                                    ?
                                        <button                                     
                                                id="btncrear"
                                                className={"btn btn-block btn-success mb-3" } 
                                                type="button" 
                                                onClick={ () => clickBtnCrear() } 
                                                disabled={ false }
                                            >
                                                <i className="fal fa-plus pr-1"></i>
                                                {confPantalla.inci.controles.btncrear.title}
                                        </button>
                                    :
                                        null
                                }
                            </FormRow>                        
                            <FormRow clases=''>
                                <div className="d-flex align-items-center w-100">                                     
                                    <div className="flex-grow-1 text-center"> 
                                        <div className="d-inline-block mx-2"> 
                                            <div className="alert alert-danger alertHelp" role="alert"> 
                                                {getNumFromData(dataTableInci, 'alert-danger')}{confPantalla.inci.controles.help.danger.title} 
                                            </div> 
                                        </div> 
                                        <div className="d-inline-block mx-2"> 
                                            <div className="alert alert-warning alertHelp" role="alert"> 
                                                {getNumFromData(dataTableInci, 'alert-warning')}{confPantalla.inci.controles.help.warning.title} 
                                            </div> 
                                        </div> 
                                        <div className="d-inline-block mx-2">
                                            <div className="alert alert-primary alertHelp" role="alert"> 
                                                {getNumFromData(dataTableInci, 'alert-primary')}{confPantalla.inci.controles.help.primary.title} 
                                            </div>
                                        </div>                                         
                                        <div className="d-inline-block mx-2"> 
                                            <button id="btnrefrescar" className="btn btn-primary" type="button" onClick={() => clickBtnRecargar()} disabled={false} style={{marginBottom : '5px'}}> 
                                                <i className="fal fa-sync pr-1"></i> 
                                                {countdown !== null 
                                                ?`${confPantalla.inci.controles.autoreload.title} ${countdown}s` 
                                                : confPantalla.inci.controles.btnrecargar.title} 
                                            </button> 
                                        </div> 
                                    </div> 
                                </div>
                            </FormRow>
                            <Tabs defaultActiveKey="tab1" id="tab1">
                                <Tab eventKey="tab1" title={ confPantalla.inci.controles.tabs.tab1.titulo }>
                                    <MiDataTableIncidencias 
                                        estadoForm= { "" }
                                        formulario={ {id:0} }
                                        id={"tableinci"}
                                        columnas={confPantalla.inci.controles.datatable.columns}
                                        data={dataTableInci}
                                        buttons={confPantalla.inci.controles.datatable.buttons}
                                        ordering={confPantalla.inci.controles.datatable.ordering}
                                        order={confPantalla.inci.controles.datatable.order}
                                        searching={confPantalla.inci.controles.datatable.searching}
                                        paging={confPantalla.inci.controles.datatable.paging}
                                        select={confPantalla.inci.controles.datatable.select}
                                        pagelength={confPantalla.inci.controles.datatable.pagelength}
                                        saveLoadingTable={ saveLoadingTableInci }
                                        loadingTable={loadingTableInci}
                                        columnactions={confPantalla.inci.controles.datatable.columnactions.title}
                                        linkColumns={confPantalla.inci.controles.datatable.linkColumns}
                                        traduction={confPantalla.config.trad.components.datatable}                                        
                                        functionClickTd={ showAddNota }                                                                                                     
                                    />                                
                                </Tab>
                                <Tab eventKey="tab2" title={ confPantalla.inci.controles.tabs.tab2.titulo }>                                    
                                    <FormRow>
                                        <MiSelect  
                                            estadoForm=  { "" }
                                            val=         { valHistorialCentro }   
                                            loading=     { creandoRegistro ? true : false }
                                            id=          { confPantalla.inci.controles.historial.centro.id }    
                                            name=        { confPantalla.inci.controles.historial.centro.name }   
                                            label=       { confPantalla.inci.controles.historial.centro.label }    
                                            placeholder= { confPantalla.inci.controles.historial.centro.placeholder }
                                            col=         { confPantalla.inci.controles.historial.centro.col } 
                                            isClearable= { confPantalla.inci.controles.historial.centro.isclearable }
                                            isSearchable={ confPantalla.inci.controles.historial.centro.issearchable }
                                            dataType=    { confPantalla.inci.controles.historial.centro.datatype }
                                            data=        { confPantalla.inci.controles.historial.centro.data }
                                            required=    { confPantalla.inci.controles.historial.centro.required } 
                                            disabled=    { confPantalla.inci.controles.historial.centro.disabled }
                                            readOnly =   { confPantalla.inci.controles.historial.centro.readonly }
                                            disablethisid={confPantalla.inci.controles.historial.centro.disablethisid}
                                            extradata=    {confPantalla.inci.controles.historial.centro.extradata}
                                            formulario= { {id:0} }   
                                            change= { (selectedItem, nameOfComponent) => handleSelect(selectedItem, nameOfComponent, saveValHistorialCentro) }
                                        />                                                                           
                                        <MiSelect  
                                            estadoForm=  { "" }
                                            val=         { valHistorialAgente }   
                                            loading=     { creandoRegistro ? true : false }
                                            id=          { confPantalla.inci.controles.historial.agente.id }    
                                            name=        { confPantalla.inci.controles.historial.agente.name }   
                                            label=       { confPantalla.inci.controles.historial.agente.label }    
                                            placeholder= { confPantalla.inci.controles.historial.agente.placeholder }
                                            col=         { confPantalla.inci.controles.historial.agente.col } 
                                            isClearable= { confPantalla.inci.controles.historial.agente.isclearable }
                                            isSearchable={ confPantalla.inci.controles.historial.agente.issearchable }
                                            dataType=    { confPantalla.inci.controles.historial.agente.datatype }
                                            data=        { confPantalla.inci.controles.historial.agente.data }
                                            required=    { confPantalla.inci.controles.historial.agente.required } 
                                            disabled=    { confPantalla.inci.controles.historial.agente.disabled }
                                            readOnly =   { confPantalla.inci.controles.historial.agente.readonly }
                                            disablethisid={confPantalla.inci.controles.historial.agente.disablethisid}
                                            extradata=    {confPantalla.inci.controles.historial.agente.extradata}
                                            formulario= { {id:0} }   
                                            change= { (selectedItem, nameOfComponent) => handleSelect(selectedItem, nameOfComponent, saveValHistorialAgente) }
                                        />
                                        <MiSelect  
                                            estadoForm=  { "" }
                                            val=         { valHistorialTipo }   
                                            loading=     { creandoRegistro ? true : false }
                                            id=          { confPantalla.inci.controles.historial.tipo.id }    
                                            name=        { confPantalla.inci.controles.historial.tipo.name }   
                                            label=       { confPantalla.inci.controles.historial.tipo.label }    
                                            placeholder= { confPantalla.inci.controles.historial.tipo.placeholder }
                                            col=         { confPantalla.inci.controles.historial.tipo.col } 
                                            isClearable= { confPantalla.inci.controles.historial.tipo.isclearable }
                                            isSearchable={ confPantalla.inci.controles.historial.tipo.issearchable }
                                            dataType=    { confPantalla.inci.controles.historial.tipo.datatype }
                                            data=        { confPantalla.inci.controles.historial.tipo.data }
                                            required=    { confPantalla.inci.controles.historial.tipo.required } 
                                            disabled=    { confPantalla.inci.controles.historial.tipo.disabled }
                                            readOnly =   { confPantalla.inci.controles.historial.tipo.readonly }
                                            disablethisid={confPantalla.inci.controles.historial.tipo.disablethisid}
                                            extradata=    {confPantalla.inci.controles.historial.tipo.extradata}
                                            formulario= { {id:0} }   
                                            change= { (selectedItem, nameOfComponent) => handleSelect(selectedItem, nameOfComponent, saveValHistorialTipo) }
                                        />
                                        <div className='form-group col-md-3' style={{paddingTop: 32}}>
                                            <Button 
                                                clases={ "col-12" }
                                                color={ "primary" }  
                                                type="button" 
                                                icono={ "" }   
                                                id=""     
                                                texto={confPantalla.inci.controles.historial.btnsearch.title}   
                                                accion={ () => searchHis() } 
                                                disabled={ valHistorialCentro == 0 && valHistorialAgente == 0 && valHistorialTipo == 0 ? true : false }
                                            />
                                        </div>
                                    </FormRow>
                                    <MiDataTableIncidencias 
                                        estadoForm= { "" }
                                        formulario={ {id:0} }
                                        id={"tablehis"}
                                        columnas={confPantalla.inci.controles.historial.datatable.columns}
                                        data={dataTableHis}
                                        buttons={confPantalla.inci.controles.historial.datatable.buttons}
                                        ordering={confPantalla.inci.controles.historial.datatable.ordering}
                                        order={confPantalla.inci.controles.historial.datatable.order}
                                        searching={confPantalla.inci.controles.historial.datatable.searching}
                                        paging={confPantalla.inci.controles.historial.datatable.paging}
                                        select={confPantalla.inci.controles.historial.datatable.select}
                                        pagelength={confPantalla.inci.controles.historial.datatable.pagelength}
                                        saveLoadingTable={ saveLoadingTableHis }
                                        loadingTable={loadingTableHis}
                                        columnactions={confPantalla.inci.controles.historial.datatable.columnactions.title}
                                        linkColumns={confPantalla.inci.controles.historial.datatable.linkColumns}
                                        traduction={confPantalla.config.trad.components.datatable}                                        
                                        functionClickTd={ showEditNota }                                                                                                     
                                    /> 
                                </Tab>
                            </Tabs>
                        </Section>   
                    </Fragment>
                    :
                    <LoadingPage />
                    }
                    </Fragment>
                }
                </section>
            </div>
            <Footer />
            {
            showModalCrearInci
            ?
                <Modal
                    show={showModalCrearInci}
                    onHide={ () => saveShowModalCrearInci(false)}                            
                    aria-labelledby="example-custom-modal-styling-title"
                    centered={true}                    
                    dialogClassName="modal-90w"                                             
                > 
                    <Modal.Header closeButton>
                        {
                            modoEditarNota == true
                            ?
                                <h4>{confPantalla.inci.controles.headereditarnota.title}</h4>
                            :
                                <h4>{confPantalla.inci.controles.modal.header.title}</h4>
                        }
                    </Modal.Header>                         
                    <Modal.Body>
                        <div style={{minHeight: '515px'}}>
                            <Tabs defaultActiveKey="tab1" id="tab1">
                                <Tab eventKey="tab1" title={ confPantalla.inci.controles.modal.tabs.tab1.titulo }>
                                    <FormRow>
                                        <MiSelect  
                                            estadoForm=  { "" }
                                            val=         { valModalCentro }   
                                            loading=     { creandoRegistro ? true : false }
                                            id=          { confPantalla.inci.controles.modal.centro.id }    
                                            name=        { confPantalla.inci.controles.modal.centro.name }   
                                            label=       { confPantalla.inci.controles.modal.centro.label }    
                                            placeholder= { confPantalla.inci.controles.modal.centro.placeholder }
                                            col=         { confPantalla.inci.controles.modal.centro.col } 
                                            isClearable= { confPantalla.inci.controles.modal.centro.isclearable }
                                            isSearchable={ confPantalla.inci.controles.modal.centro.issearchable }
                                            dataType=    { confPantalla.inci.controles.modal.centro.datatype }
                                            data=        { confPantalla.inci.controles.modal.centro.data }
                                            required=    { confPantalla.inci.controles.modal.centro.required } 
                                            disabled=    { modoEditarNota == true ? true : confPantalla.inci.controles.modal.centro.disabled }
                                            readOnly =   { modoEditarNota == true ? "readonly" : confPantalla.inci.controles.modal.centro.readonly }
                                            disablethisid={confPantalla.inci.controles.modal.centro.disablethisid}
                                            extradata=    {confPantalla.inci.controles.modal.centro.extradata}
                                            formulario= { {id:0} }   
                                            change= { (selectedItem, nameOfComponent) => handleSelect(selectedItem, nameOfComponent, saveValModalCentro) }                                
                                        />
                                        {
                                            modoEditarNota == true
                                            ?
                                                <MiSelect  
                                                    estadoForm=  { "" }
                                                    val=         { valModalEstado }   
                                                    loading=     { creandoRegistro ? true : false }
                                                    id=          { confPantalla.inci.controles.modal.estado.id }    
                                                    name=        { confPantalla.inci.controles.modal.estado.name }   
                                                    label=       { confPantalla.inci.controles.modal.estado.label }    
                                                    placeholder= { confPantalla.inci.controles.modal.estado.placeholder }
                                                    col=         { confPantalla.inci.controles.modal.estado.col } 
                                                    isClearable= { confPantalla.inci.controles.modal.estado.isclearable }
                                                    isSearchable={ confPantalla.inci.controles.modal.estado.issearchable }
                                                    dataType=    { confPantalla.inci.controles.modal.estado.datatype }
                                                    data=        { confPantalla.inci.controles.modal.estado.data }
                                                    required=    { confPantalla.inci.controles.modal.estado.required } 
                                                    disabled=    { confPantalla.inci.controles.modal.estado.disabled }
                                                    readOnly =   { confPantalla.inci.controles.modal.estado.readonly }
                                                    disablethisid={confPantalla.inci.controles.modal.estado.disablethisid}
                                                    extradata=    {confPantalla.inci.controles.modal.estado.extradata}
                                                    formulario= { {id:0} }   
                                                    change= { (selectedItem, nameOfComponent) => handleSelect(selectedItem, nameOfComponent, saveValModalEstado) }                                
                                                />
                                            :
                                                null
                                        }
                                    </FormRow>
                                    <FormRow>
                                        <MiSelect  
                                            estadoForm=  { "" }
                                            val=         { valModalAgente }   
                                            loading=     { creandoRegistro ? true : false }
                                            id=          { confPantalla.inci.controles.modal.agente.id }    
                                            name=        { confPantalla.inci.controles.modal.agente.name }   
                                            label=       { confPantalla.inci.controles.modal.agente.label }    
                                            placeholder= { confPantalla.inci.controles.modal.agente.placeholder }
                                            col=         { confPantalla.inci.controles.modal.agente.col } 
                                            isClearable= { confPantalla.inci.controles.modal.agente.isclearable }
                                            isSearchable={ confPantalla.inci.controles.modal.agente.issearchable }
                                            dataType=    { confPantalla.inci.controles.modal.agente.datatype }
                                            data=        { confPantalla.inci.controles.modal.agente.data }
                                            required=    { confPantalla.inci.controles.modal.agente.required } 
                                            disabled=    { confPantalla.inci.controles.modal.agente.disabled }
                                            readOnly =   { confPantalla.inci.controles.modal.agente.readonly }
                                            disablethisid={confPantalla.inci.controles.modal.agente.disablethisid}
                                            extradata=    {confPantalla.inci.controles.modal.agente.extradata}
                                            formulario= { {id:0} }   
                                            change= { (selectedItem, nameOfComponent) => handleSelect(selectedItem, nameOfComponent, saveValModalAgente) }                                
                                        />
                                        <MiSelect  
                                            estadoForm=  { "" }
                                            val=         { valModalTipo }   
                                            loading=     { creandoRegistro ? true : false }
                                            id=          { confPantalla.inci.controles.modal.tipo.id }    
                                            name=        { confPantalla.inci.controles.modal.tipo.name }   
                                            label=       { confPantalla.inci.controles.modal.tipo.label }    
                                            placeholder= { confPantalla.inci.controles.modal.tipo.placeholder }
                                            col=         { confPantalla.inci.controles.modal.tipo.col } 
                                            isClearable= { confPantalla.inci.controles.modal.tipo.isclearable }
                                            isSearchable={ confPantalla.inci.controles.modal.tipo.issearchable }
                                            dataType=    { confPantalla.inci.controles.modal.tipo.datatype }
                                            data=        { confPantalla.inci.controles.modal.tipo.data }
                                            required=    { confPantalla.inci.controles.modal.tipo.required } 
                                            disabled=    { confPantalla.inci.controles.modal.tipo.disabled }
                                            readOnly =   { confPantalla.inci.controles.modal.tipo.readonly }
                                            disablethisid={confPantalla.inci.controles.modal.tipo.disablethisid}
                                            extradata=    {confPantalla.inci.controles.modal.tipo.extradata}
                                            formulario= { {id:0} }   
                                            change= { (selectedItem, nameOfComponent) => handleSelect(selectedItem, nameOfComponent, saveValModalTipo) }                                
                                        />
                                        <MiSelect  
                                            estadoForm=  { "" }
                                            val=         { valModalPrioridad }   
                                            loading=     { creandoRegistro ? true : false }
                                            id=          { confPantalla.inci.controles.modal.prioridad.id }    
                                            name=        { confPantalla.inci.controles.modal.prioridad.name }   
                                            label=       { confPantalla.inci.controles.modal.prioridad.label }    
                                            placeholder= { confPantalla.inci.controles.modal.prioridad.placeholder }
                                            col=         { confPantalla.inci.controles.modal.prioridad.col } 
                                            isClearable= { confPantalla.inci.controles.modal.prioridad.isclearable }
                                            isSearchable={ confPantalla.inci.controles.modal.prioridad.issearchable }
                                            dataType=    { confPantalla.inci.controles.modal.prioridad.datatype }
                                            data=        { confPantalla.inci.controles.modal.prioridad.data }
                                            required=    { confPantalla.inci.controles.modal.prioridad.required } 
                                            disabled=    { confPantalla.inci.controles.modal.prioridad.disabled }
                                            readOnly =   { confPantalla.inci.controles.modal.prioridad.readonly }
                                            disablethisid={confPantalla.inci.controles.modal.prioridad.disablethisid}
                                            extradata=    {confPantalla.inci.controles.modal.prioridad.extradata}
                                            formulario= { {id:0} }   
                                            change= { (selectedItem, nameOfComponent) => handleSelect(selectedItem, nameOfComponent, saveValModalPrioridad) }
                                        />
                                        <FormInput                                                        
                                            estadoForm= { "" }
                                            value=      { valModalFecha }
                                            loading=    { creandoRegistro ? true : false }
                                            id=         { confPantalla.inci.controles.modal.fec.id }   
                                            type=       { confPantalla.inci.controles.modal.fec.type }  
                                            name=       { confPantalla.inci.controles.modal.fec.name }   
                                            label=      { confPantalla.inci.controles.modal.fec.label }                                                       
                                            placeholder={ confPantalla.inci.controles.modal.fec.placeholder }    
                                            col=        { confPantalla.inci.controles.modal.fec.col } 
                                            required=   { confPantalla.inci.controles.modal.fec.required }
                                            readonly=   { confPantalla.inci.controles.modal.fec.readonly }                                                
                                            disabled=   { confPantalla.inci.controles.modal.fec.disabled }
                                            change=     { e => handleInput(e,saveValModalFecha) }                                 
                                        />
                                    </FormRow>
                                    <FormRow>
                                        <FormInput 
                                            estadoForm= { "" }
                                            value=      { valModalDescripcion }
                                            loading=    { creandoRegistro ? true : false }
                                            id=         { confPantalla.inci.controles.modal.descripcion.id }   
                                            type=       { confPantalla.inci.controles.modal.descripcion.type }  
                                            name=       { confPantalla.inci.controles.modal.descripcion.name }   
                                            label=      { confPantalla.inci.controles.modal.descripcion.label }                                                       
                                            placeholder={ confPantalla.inci.controles.modal.descripcion.placeholder }    
                                            col=        { confPantalla.inci.controles.modal.descripcion.col } 
                                            required=   { confPantalla.inci.controles.modal.descripcion.required }
                                            readonly=   { confPantalla.inci.controles.modal.descripcion.readonly }                                                
                                            disabled=   { confPantalla.inci.controles.modal.descripcion.disabled }
                                            change=     { e => handleInput(e, saveValModalDescripcion) }                                
                                        />
                                    </FormRow>
                                    <FormRow>
                                        <TextArea                                                        
                                            estadoForm= { '' }
                                            value=      { valModalNotaAlCrear }
                                            loading=    { false }
                                            id=         { confPantalla.inci.controles.modal.notasadd.id }   
                                            name=       { confPantalla.inci.controles.modal.notasadd.name }
                                            rows=       { confPantalla.inci.controles.modal.notasadd.rows }      
                                            label=      { confPantalla.inci.controles.modal.notasadd.label }                                                       
                                            placeholder={ confPantalla.inci.controles.modal.notasadd.placeholder }    
                                            col=        { confPantalla.inci.controles.modal.notasadd.col } 
                                            required=   { confPantalla.inci.controles.modal.notasadd.required }
                                            readonly=   { confPantalla.inci.controles.modal.notasadd.readonly }                                                
                                            disabled=   { confPantalla.inci.controles.modal.notasadd.disabled }
                                            change=     { e => handleInput(e, saveValModalNotaAlCrear) }
                                            onBlur=     {null} 
                                        />
                                    </FormRow>                                                                      
                                </Tab>
                                <Tab eventKey="tab2" title={ confPantalla.inci.controles.modal.tabs.tab2.titulo }>
                                    <UplmPlus2
                                        id={ confPantalla.inci.controles.modal.uplmplus.id }
                                        adjuntos={ adjuntos }
                                        saveAdjuntos= { saveAdjuntos }  
                                        adjuntosExistentes= { adjuntosExistentes } 
                                        saveAdjuntosExistentes= { saveAdjuntosExistentes }
                                        estadoForm= { "" }
                                        loading= { creandoRegistro ? true : false }
                                        col={12}
                                        readonly={ confPantalla.inci.controles.modal.uplmplus.readonly }
                                        traduction={confPantalla.config.trad.components}                                                            
                                        datatable={ confPantalla.inci.controles.modal.uplmplus.datatable }
                                        select={ confPantalla.inci.controles.modal.uplmplus.select }
                                        onlyAdj={true}
                                        onlyAdminsEditAdj={true}
                                    />
                                </Tab>
                            </Tabs>
                        </div>
                        {
                            creandoRegistro 
                            ?
                                <div className="text-center">
                                    <Spinner animation="border" />
                                </div>
                            :
                            null
                        }
                        <p className="font-italic text-center">{ confPantalla.inci.controles.modal.required.title }</p> 
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='text-right'>                                                                   
                            <Button 
                                clases={ "" }
                                color={ "success" }  
                                type="button" 
                                icono={ "" }   
                                id=""     
                                texto={modoEditarNota == true ? confPantalla.inci.controles.modal.btnguardarmodal.title : confPantalla.inci.controles.modal.btncrearmodal.title}   
                                accion={modoEditarNota == true ? () => guardarRegistroModal() : () => crearRegistroModal() } 
                                disabled={ 
                                    (creandoRegistro || valModalCentro == 0 || valModalDescripcion == '' || valModalAgente == 0 || valModalTipo == 0 || valModalPrioridad == 0 || valModalFecha == '' || valModalEstado == 0) && modoEditarNota == true 
                                    ? true 
                                    : (creandoRegistro || valModalCentro == 0 || valModalDescripcion == '' || valModalAgente == 0 || valModalTipo == 0 || valModalPrioridad == 0 || valModalFecha == '') && modoEditarNota == false 
                                    ? true
                                    : false 
                                }
                            /> 
                            <Button 
                                clases={ "" }
                                color={ "danger" }  
                                type="button" 
                                icono={ "" }   
                                id=""     
                                texto={confPantalla.inci.controles.modal.btncerrarmodal.title}   
                                accion={ () => saveShowModalCrearInci(false) } 
                                disabled={ false }
                            />                                    
                        </div>
                    </Modal.Footer>                            
                </Modal>
            :
                null 
            }
            {
            showModalAddNota
            ?
                <Modal
                    show={showModalAddNota}
                    onHide={ () => saveShowModalAddNota(false)}                            
                    aria-labelledby="example-custom-modal-styling-title"
                    centered={true}                    
                    dialogClassName="modal-90w"                                             
                >
                    <Modal.Header closeButton>
                        <h4>{descripcion}</h4>
                    </Modal.Header>                                              
                    <Modal.Body>
                        <div style={{minHeight: '600px'}}>
                            <Tabs defaultActiveKey="tab1" id="tab1">
                                <Tab eventKey="tab1" title={ confPantalla.inci.controles.modal.tabs.tab1.titulo }>
                                    <FormRow>
                                        <div className="chat-container container-fluid" ref={chatContainerRef}> 
                                            {                                                
                                                notas != ''
                                                ?
                                                notas.split('##@##').map((line, index) => {                                                                      
                                                    if (regex.test(line)) { 
                                                        if (currentMessage) { 
                                                            const [date, ...senderRest] = currentMessage.sender.split(' - '); 
                                                            const sender = senderRest.join(' - '); 
                                                            const body = currentMessage.body.replace(/\n/g, '<br>'); 
                                                            currentMessage = { sender: line, body: '' }; 
                                                            return ( 
                                                                <div key={index} className="message mb-3"> 
                                                                    <div className="sender">
                                                                        <span className='ml-1' style={{ fontWeight: 'bold', color: '#212529' }}>{date} - </span>
                                                                        {sender} 
                                                                    </div> 
                                                                    <div className="body" dangerouslySetInnerHTML={{ __html: body }} />
                                                                </div> ); 
                                                        } 
                                                        currentMessage = { sender: line, body: '' }; 
                                                    } else { 
                                                        if (currentMessage) { 
                                                            currentMessage.body += (currentMessage.body ? '\n' : '') + line; 
                                                        } 
                                                    } if (index === notas.split('##@##').length - 1 && currentMessage) { 
                                                        const [date, ...senderRest] = currentMessage.sender.split(' - '); 
                                                        const sender = senderRest.join(' - '); 
                                                        const body = currentMessage.body.replace(/\n/g, '<br>'); 
                                                        return ( 
                                                            <div key={index} className="message mb-3"> 
                                                                <div className="sender">
                                                                    <span className='ml-1' style={{ fontWeight: 'bold', color: '#212529' }}>{date} - </span>
                                                                    {sender} 
                                                                </div> 
                                                                <div className="body" dangerouslySetInnerHTML={{ __html: body }} /> 
                                                            </div> ); 
                                                    } 
                                                    return null;
                                                })
                                                : 
                                                null
                                            }
                                            </div>
                                    </FormRow>
                                    <FormRow>
                                        <MiSelect  
                                            estadoForm=  { "" }
                                            val=         { ageAsignado }   
                                            loading=     { creandoRegistro ? true : false }
                                            id=          { confPantalla.inci.controles.modalnotas.agente.id }    
                                            name=        { confPantalla.inci.controles.modalnotas.agente.name }   
                                            label=       { confPantalla.inci.controles.modalnotas.agente.label }    
                                            placeholder= { confPantalla.inci.controles.modalnotas.agente.placeholder }
                                            col=         { confPantalla.inci.controles.modalnotas.agente.col } 
                                            isClearable= { confPantalla.inci.controles.modalnotas.agente.isclearable }
                                            isSearchable={ confPantalla.inci.controles.modalnotas.agente.issearchable }
                                            dataType=    { confPantalla.inci.controles.modalnotas.agente.datatype }
                                            data=        { confPantalla.inci.controles.modalnotas.agente.data }
                                            required=    { confPantalla.inci.controles.modalnotas.agente.required } 
                                            disabled=    { confPantalla.inci.controles.modalnotas.agente.disabled }
                                            readOnly =   { confPantalla.inci.controles.modalnotas.agente.readonly }
                                            disablethisid={confPantalla.inci.controles.modalnotas.agente.disablethisid}
                                            extradata=    {confPantalla.inci.controles.modalnotas.agente.extradata}
                                            formulario= { {id:0} }   
                                            change= { (selectedItem, nameOfComponent) => handleSelect(selectedItem, nameOfComponent, saveAgeAsignado) }
                                        />
                                        <MiSelect  
                                            estadoForm=  { "" }
                                            val=         { prioridad }   
                                            loading=     { creandoRegistro ? true : false }
                                            id=          { confPantalla.inci.controles.modalnotas.prioridad.id }    
                                            name=        { confPantalla.inci.controles.modalnotas.prioridad.name }   
                                            label=       { confPantalla.inci.controles.modalnotas.prioridad.label }    
                                            placeholder= { confPantalla.inci.controles.modalnotas.prioridad.placeholder }
                                            col=         { confPantalla.inci.controles.modalnotas.prioridad.col } 
                                            isClearable= { confPantalla.inci.controles.modalnotas.prioridad.isclearable }
                                            isSearchable={ confPantalla.inci.controles.modalnotas.prioridad.issearchable }
                                            dataType=    { confPantalla.inci.controles.modalnotas.prioridad.datatype }
                                            data=        { confPantalla.inci.controles.modalnotas.prioridad.data }
                                            required=    { confPantalla.inci.controles.modalnotas.prioridad.required } 
                                            disabled=    { confPantalla.inci.controles.modalnotas.prioridad.disabled }
                                            readOnly =   { confPantalla.inci.controles.modalnotas.prioridad.readonly }
                                            disablethisid={confPantalla.inci.controles.modalnotas.prioridad.disablethisid}
                                            extradata=    {confPantalla.inci.controles.modalnotas.prioridad.extradata}
                                            formulario= { {id:0} }   
                                            change= { (selectedItem, nameOfComponent) => handleSelect(selectedItem, nameOfComponent, savePrioridad) }
                                        />
                                    </FormRow>
                                    <FormRow>
                                        <TextArea                                                        
                                            estadoForm= { '' }
                                            value=      { notaAdd }
                                            loading=    { false }
                                            id=         { confPantalla.inci.controles.modalnotas.notasadd.id }   
                                            name=       { confPantalla.inci.controles.modalnotas.notasadd.name }
                                            rows=       { confPantalla.inci.controles.modalnotas.notasadd.rows }      
                                            label=      { confPantalla.inci.controles.modalnotas.notasadd.label }                                                       
                                            placeholder={ confPantalla.inci.controles.modalnotas.notasadd.placeholder }    
                                            col=        { confPantalla.inci.controles.modalnotas.notasadd.col } 
                                            required=   { confPantalla.inci.controles.modalnotas.notasadd.required }
                                            readonly=   { confPantalla.inci.controles.modalnotas.notasadd.readonly }                                                
                                            disabled=   { confPantalla.inci.controles.modalnotas.notasadd.disabled }
                                            change=     { e => handleInput(e, saveNotaAdd) }
                                            onBlur=     {null} 
                                        />
                                    </FormRow>
                                </Tab>
                                <Tab eventKey="tab2" title={ confPantalla.inci.controles.modal.tabs.tab2.titulo }>
                                    <UplmPlus2
                                        id={ confPantalla.inci.controles.modal.uplmplus.id }
                                        adjuntos={ adjuntos }
                                        saveAdjuntos= { saveAdjuntos }  
                                        adjuntosExistentes= { adjuntosExistentes } 
                                        saveAdjuntosExistentes= { saveAdjuntosExistentes }
                                        estadoForm= { "" }
                                        loading= { creandoRegistro ? true : false }
                                        col={12}
                                        readonly={ confPantalla.inci.controles.modal.uplmplus.readonly }
                                        traduction={confPantalla.config.trad.components}                                                            
                                        datatable={ confPantalla.inci.controles.modal.uplmplus.datatable }
                                        select={ confPantalla.inci.controles.modal.uplmplus.select }
                                        onlyAdj={true}
                                        onlyAdminsEditAdj={true}
                                    />
                                </Tab>
                            </Tabs>                                                                                               
                        </div>
                        {
                            creandoRegistro 
                            ?
                                <div className="text-center">
                                    <Spinner animation="border" />
                                </div>
                            :
                            null
                        } 
                        <p className="font-italic text-center">{ confPantalla.inci.controles.modal.required.title }</p>
                        <div className='flex-center'>
                            {
                                getDecodedCookie('nivel') == '1' || getDecodedCookie('nivel') == '2'
                                ?
                                <Button 
                                    clases={ "btn col-6 mb-1" }
                                    color={ "danger" }  
                                    type="button" 
                                    icono={ "" }   
                                    id=""     
                                    texto={confPantalla.inci.controles.modalnotas.btncerrarnotamodal.title}   
                                    accion={ () => cerrarNotaSwal() } 
                                    disabled={ false }
                                />
                                :
                                    null
                            }
                            <Button 
                                clases={ getDecodedCookie('nivel') == '1' || getDecodedCookie('nivel') == '2' ? "btn col-6 mb-1" : "btn-block mb-1" }
                                color={ "success" }  
                                type="button" 
                                icono={ "" }   
                                id=""     
                                texto={confPantalla.inci.controles.modalnotas.btncrearmodal.title}   
                                accion={ () => crearNota() } 
                                disabled={ ageAsignado == 0 || prioridad == 0 ? true : false }
                            />
                        </div>                       
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='text-right'>                             
                            <Button 
                                clases={ "" }
                                color={ "danger" }  
                                type="button" 
                                icono={ "" }   
                                id=""     
                                texto={confPantalla.inci.controles.modalnotas.btncerrarmodal.title}   
                                accion={ () => saveShowModalAddNota(false) } 
                                disabled={ false }
                            />                                    
                        </div>
                    </Modal.Footer>                            
                </Modal>
            :
                null 
            }          
        </div>
    </SRLWrapper>
    );
}

export default Inci