import React from 'react';

import { SRLWrapper } from "simple-react-lightbox";
import DatePicker from 'rsuite/DatePicker';

import { getIconoTable, getDatePickerDaysName }from '../../../helpers/helperApp';

import FixRequiredSelect from '../../../helpers/FixRequiredSelect';
import BaseSelect from "react-select";


function UplmPlus2PendingList(props){
    
    const { id, adjuntos, saveAdjuntos, readonly, estadoForm, traduction, optionSelect, datatable } = props;
    
    const Select = props => (
        <FixRequiredSelect
            {...props}
            SelectComponent={BaseSelect}
            options={optionSelect || optionSelect}
        />
        );

    const customStyles = {
        control: (base, state) => ({
            ...base,
            borderColor:'#ced4da',
            borderColor: state.isFocused ? '#80bdff': '#ced4da',
            outline: 0,
            boxShadow: state.isFocused ? '0 0 0 0.2rem rgb(0 123 255 / 25%)' : 'inherit',
            background: readonly === "readonly" || estadoForm === "readonly" ? "#e9ecef" : "white",
            color: "#495057"
          }),
          option: (provided, state) => ({
            ...provided,
            color: "#212529"
          }),
          singleValue: (provided) => ({
            ...provided,
            color: "#495057"
          }),
          menuPortal: (provided) => ({
            ...provided,            
            zIndex: 9999
          })  
    }
    
    const _URL = window.URL || window.webkitURL;

    const handleDeleteTmpAdjunto = async e => {
        e.preventDefault();

        await saveAdjuntos([]);

        const adjCancelar = e.target.dataset.id;
        const index = e.target.dataset.index;

        let noBorrar = adjuntos.filter(adjunto => adjunto.name != adjCancelar)

        //Actualizamos el state
        saveAdjuntos(noBorrar);

    }

    const handleChangeSelect = (selectedItem, nameOfComponent) => {

        const nameAdj = nameOfComponent.name.split("**")[1];
        const auxAdjExistentes = adjuntos.map((adjunto) => {

            if( adjunto.name == nameAdj ){
                if( selectedItem ){
                    adjunto.idtip = selectedItem.value;
                }else{
                    adjunto.idtip = "";
                }
            }
            return adjunto;
        });
        saveAdjuntos(auxAdjExistentes);
        
    }

    const handleChangeDescription = (e) => {
        const nameAdj = e.target.name.split("**")[1];
        const value = e.target.value;

        const auxAdjExistentes = adjuntos.map((adjunto) => {

            if( adjunto.name == nameAdj ){
                adjunto.description = e.target.value;
            }
            return adjunto;
        });

        saveAdjuntos(auxAdjExistentes);
    }

    const handleChangeFCad = (val, event, name) =>{
        // const value = e.target.value;
        const nameAdj = name.split("**")[1];
        let value;
        if(val == null){
            value = '0000-00-00';
        }else{
            const anho = val.getFullYear()
            const mes = val.getMonth()+1 < 10 ? '0'+(val.getMonth()+1) : val.getMonth()+1;
            const dia = val.getDate() < 10 ? '0'+val.getDate() : val.getDate();
            const horas = val.getHours() < 10 ? '0' + val.getHours() : val.getHours(); 
            const minutos = val.getMinutes() < 10 ? '0' + val.getMinutes() : val.getMinutes();
            // value = `${anho}-${mes}-${dia}T${horas}:${minutos}`;
            value = `${anho}-${mes}-${dia}`;
        }
        const auxAdjExistentes = adjuntos.map((adjunto) => {

            if( adjunto.name == nameAdj ){
                adjunto.fcad = value;
            }
            return adjunto;
        });

        saveAdjuntos(auxAdjExistentes);
    }

    return(
            <SRLWrapper>                
                {
                    adjuntos.length > 0
                    ?
                        <table id={"pending-"+id} className={"pending-"+id+" table table-sm table-bordered table-hover dataTable"} style={{width:"100%"}}>
                            <thead>
                                <tr>
                            {
                                datatable.columns.map((columna, index, columns) => {                                
                                    if( columna.data != "groupby" ){
                                        return(
                                            <th key={index} style={{width: columna.width}} >{columna.title}</th>
                                        )
                                    }
                                })
                            }
                                </tr>
                            </thead>
                            <tbody>
                            {                
                                adjuntos.map((adj, index) => {
                                    if( adj.urlAdd === undefined ){
                                        adj.id=id;
                                        let confIco;
                                        if( adj["type"].split("/")[0] === "image" ){
                                            adj.src = _URL.createObjectURL(adj);
                                            adj.url = adj.src.replace("blob:","");
                                        }else{
                                            let ext = adj["name"].split(".")[adj["name"].split(".").length-1];
                                            confIco = getIconoTable(ext);
                                        } 
                                        return( 
                                            <tr key={index} className={'row-'+index}>
                                                <td>
                                                    { adj.src ? 
                                                            <div>                                   
                                                                    <div className="containerImgTablePending">
                                                                        <img className="imgUplmTablePending" src={adj.src} alt={adj.name} />
                                                                    </div> 
                                                            </div>
                                                            :
                                                            <div className="text-center">
                                                                <i className={`${confIco["icono"]}`} style={{color:confIco["color"]}}></i>
                                                            </div>                                               
                                                    }
                                                </td>
                                                <td>
                                                    <div>                                        
                                                        <textarea 
                                                            placeholder={traduction.uplm.textarea.title} 
                                                            className="form-control fileNew" 
                                                            rows="1" 
                                                            defaultValue={adj.description != "" &&  adj.description != null && adj.description != undefined  ? adj.description : adj.name}
                                                            onChange={ handleChangeDescription } 
                                                            name={"fileNew**"+adj.name} 
                                                            style={{resize: "none"}} >                                                
                                                        </textarea>                                            
                                                    </div>
                                                </td>
                                                <td>
                                                <div className="">                                        
                                                    {
                                                            readonly !== "true" && readonly !== "readonly"  && estadoForm !== "readonly" && estadoForm !== "disabled"
                                                            ?  
                                                                <Select
                                                                    className="fileNew"  
                                                                    options={optionSelect}
                                                                    name={"fileNew**"+adj.name+"**idtip"}             
                                                                    id={adj.name}
                                                                    placeholder={ "" } 
                                                                    // value={optionSelect.filter(option => option.value == adj.idtip)}
                                                                    onChange={ handleChangeSelect }
                                                                    defaultValue={optionSelect.filter(option => option.value == adj.idtip)}
                                                                    required={false}
                                                                    isDisabled={ false }
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    isMulti={false}
                                                                    noOptionsMessage={ ({ inputValue: string }) => traduction.select.noresult.title } 
                                                                    // menuIsOpen={false}
                                                                    menuIsOpen={undefined}
                                                                    isReadOnly={false}
                                                                    styles={customStyles} 
                                                                    menuPortalTarget={document.querySelector('body')}
                                                                    menuPlacement={"auto"}
                                                                    formatOptionLabel={
                                                                        function(data) {
                                                                            return (
                                                                                <span dangerouslySetInnerHTML={{ __html: data.label }} />
                                                                            );
                                                                        }                                                                        
                                                                    }
                                                                />   
                                                            :
                                                                <Select
                                                                    className="fileNew"  
                                                                    options={optionSelect}
                                                                    name={"fileNew**"+adj.name+"**idtip"}             
                                                                    id={adj.name}
                                                                    placeholder={ "" } 
                                                                    // value={optionSelect.filter(option => option.value == adj.idtip)}
                                                                    onChange={ handleChangeSelect }
                                                                    defaultValue={optionSelect.filter(option => option.value == adj.idtip)}
                                                                    required={false}
                                                                    isDisabled={ false }
                                                                    isClearable={false}
                                                                    isSearchable={false}
                                                                    isMulti={false}
                                                                    noOptionsMessage={ ({ inputValue: string }) => traduction.select.noresult.title } 
                                                                    // menuIsOpen={false}
                                                                    menuIsOpen={undefined}
                                                                    isReadOnly={false}
                                                                    styles={customStyles} 
                                                                    menuPortalTarget={document.querySelector('body')}
                                                                    menuPlacement={"auto"}
                                                                    formatOptionLabel={
                                                                        function(data) {
                                                                            return (
                                                                                <span dangerouslySetInnerHTML={{ __html: data.label }} />
                                                                            );
                                                                        }                                                                        
                                                                    }
                                                                />                                                
                                                        }                                          
                                                    </div>        
                                                </td>
                                        
                                                <td>
                                                <div className="" style={{minWidth: '150px'}}>                                        
                                                    {
                                                        readonly !== "true" && readonly !== "readonly"  && estadoForm !== "readonly" && estadoForm !== "disabled"
                                                        ?                               
                                                            // <input  type={ "date" } 
                                                            //         className={ "form-control fileNew"} 
                                                            //         id={ adj.name } 
                                                            //         name={"fileNew**"+adj.name+"**fcad"} 
                                                            //         placeholder={ "" }                                            
                                                            //         readOnly={ false }
                                                            //         defaultValue={adj.fcad}
                                                            //         onChange={ handleChangeFCad }                                          
                                                            // />
                                                            <DatePicker
                                                                id={adj.name}
                                                                // name={nombre+id+"**"+rowData.id+"**fcad"}
                                                                placeholder={' '}
                                                                format={"yyyy-MM-dd"}
                                                                defaultValue={ adj.fcad == '0000-00-00T00:00' || adj.fcad == '0000-00-00' ||  adj.fcad == '' ? null : new Date(adj.fcad) }
                                                                onOk={(value, event) => handleChangeFCad(value, event, "fileNew**"+adj.name+"**fcad")}
                                                                onChange={ (value, event) => handleChangeFCad(value, event, "fileNew**"+adj.name+"**fcad") }                                
                                                                readOnly={ false }
                                                                className={''}
                                                                disabled={ false } 
                                                                // showTime
                                                                block={true}
                                                                weekStart={1}
                                                                locale={getDatePickerDaysName()}
                                                                placement={'auto'}
                                                            /> 
                                                        :
                                                            // <input  type={ "date" } 
                                                            //         className={ "form-control fileNew"} 
                                                            //         id={ adj.name } 
                                                            //         name={"fileNew**"+adj.name+"**fcad"} 
                                                            //         placeholder={ "" }
                                                            //         readOnly={ true }     
                                                            //         defaultValue={adj.fcad}
                                                            //         onChange={ handleChangeFCad }                                     
                                                            // />
                                                            <DatePicker
                                                                id={adj.name}
                                                                // name={nombre+id+"**"+rowData.id+"**fcad"}
                                                                placeholder={' '}
                                                                format={"yyyy-MM-dd"}
                                                                defaultValue={ adj.fcad == '0000-00-00T00:00' || adj.fcad == '0000-00-00' ||  adj.fcad == '' ? null : new Date(adj.fcad) }
                                                                onOk={(value, event) => handleChangeFCad(value, event, "fileNew**"+adj.name+"**fcad")}
                                                                onChange={ (value, event) => handleChangeFCad(value, event, "fileNew**"+adj.name+"**fcad") }                                
                                                                readOnly={ true }
                                                                className={''}
                                                                disabled={ false } 
                                                                // showTime
                                                                block={true}
                                                                weekStart={1}
                                                                locale={getDatePickerDaysName()}
                                                                placement={'auto'}
                                                            /> 
                                                    }
                                                </div>      
                                                </td>
                                                <td className="text-center">
                                                    <span 
                                                        id={ adj.name } 
                                                        className="btn btn-flat btn-outline-warning deleteRow" 
                                                        style={{border: 0}}
                                                        data-id={ adj.name } 
                                                        data-index={ index }
                                                        onClick={ e => { handleDeleteTmpAdjunto(e) } }
                                                    >
                                                        <i  
                                                                className="fal fa-ban deleteRow"
                                                                id={ adj.name } 
                                                                data-id={ adj.name }
                                                                data-index={ index }
                                                                // onClick={ e => { handleDeleteTmpAdjunto(e) }}
                                                            >
                                                        </i>
                                                    </span>
                                                </td>
                                            </tr>                                                                                
                                        ) 
                                    }else{
                                        return(
                                            <tr key={index}>
                                                    <td>
                                                        <div className="text-center">
                                                            <a href={ adj.urlAdd.toLowerCase().includes("https") || adj.urlAdd.toLowerCase().includes("http") || adj.urlAdd.toLowerCase().includes("file:///")?  adj.urlAdd : "https://"+adj.urlAdd} target="_blank" >
                                                                <i className="fab fa-chrome fa-2x"></i>
                                                            </a>
                                                        </div>                                               
                                                    </td>
                                                    <td>
                                                        <div>                                        
                                                            <textarea 
                                                                placeholder={traduction.uplm.textarea.title} 
                                                                className="form-control fileNew" 
                                                                rows="1" 
                                                                defaultValue={adj.description != "" &&  adj.description != null && adj.description != undefined  ? adj.description : adj.name}
                                                                onChange={ handleChangeDescription }  
                                                                name={"fileNewUrl**"+adj.urlAdd} 
                                                                style={{resize: "none"}} >                                                
                                                            </textarea>
                                                            <input  type={ "hidden" } 
                                                                className={ "form-control"} 
                                                                id={ adj.name } 
                                                                name={"fileNewUrl**"+adj.name+"**name"} 
                                                                placeholder={ "" }
                                                                readOnly={ false }
                                                                value={adj.name}                                                                                                  
                                                            />                                             
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">                                        
                                                            <Select 
                                                                className={ "fileNew"}
                                                                options={optionSelect}
                                                                name={"fileNewUrl**"+adj.name+"**idtip"}             
                                                                id={adj.name}
                                                                placeholder={ "" } 
                                                                // value={optionSelect.filter(option => option.value == adj.idtip)}
                                                                onChange={ handleChangeSelect }
                                                                defaultValue={optionSelect.filter(option => option.value == adj.idtip)}
                                                                required={false}
                                                                isDisabled={ false }
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                isMulti={false}
                                                                noOptionsMessage={ ({ inputValue: string }) => traduction.select.noresult.title } 
                                                                // menuIsOpen={false}
                                                                menuIsOpen={undefined}
                                                                isReadOnly={false}
                                                                styles={customStyles} 
                                                                menuPortalTarget={document.querySelector('body')}
                                                                menuPlacement={"auto"}
                                                                formatOptionLabel={
                                                                    function(data) {
                                                                        return (
                                                                            <span dangerouslySetInnerHTML={{ __html: data.label }} />
                                                                        );
                                                                    }                                                                        
                                                                }
                                                            /> 
                                                        </div>                                                      
                                                    </td>
                                                    <td style={{minWidth: '150px'}}>
                                                        {/* <input  type={ "date" } 
                                                                className={ "form-control fileNew"} 
                                                                id={ adj.name } 
                                                                name={"fileNewUrl**"+adj.name+"**fcad"} 
                                                                placeholder={ "" }
                                                                readOnly={ false }
                                                                defaultValue={adj.fcad}
                                                                onChange={ handleChangeFCad }                                         
                                                        /> */}
                                                        <DatePicker
                                                            id={adj.name}
                                                            // name={nombre+id+"**"+rowData.id+"**fcad"}
                                                            placeholder={' '}
                                                            format={"yyyy-MM-dd"}
                                                            defaultValue={ adj.fcad == '0000-00-00T00:00' || adj.fcad == '0000-00-00' ||  adj.fcad == '' ? null : new Date(adj.fcad) }
                                                            onOk={(value, event) => handleChangeFCad(value, event, "fileNewUrl**"+adj.name+"**fcad")}
                                                            onChange={ (value, event) => handleChangeFCad(value, event, "fileNewUrl**"+adj.name+"**fcad") }
                                                            readOnly={ false }
                                                            className={''}
                                                            disabled={ false } 
                                                            // showTime
                                                            block={true}
                                                            weekStart={1}
                                                            locale={getDatePickerDaysName()}
                                                            placement={'auto'}
                                                        /> 
                                                    </td>
                                                    <td className="text-center">
                                                        <span 
                                                            id={ adj.name } 
                                                            className="btn btn-flat btn-outline-warning deleteRow" 
                                                            style={{border: 0}}
                                                            data-id={ adj.name } 
                                                            data-index={ index }
                                                            onClick={ e => { handleDeleteTmpAdjunto(e) } }
                                                        >
                                                            <i  
                                                                    className="fal fa-ban deleteRow"
                                                                    id={ adj.name } 
                                                                    data-id={ adj.name }
                                                                    data-index={ index }
                                                                    // onClick={ e => { handleDeleteTmpAdjunto(e) }}
                                                                >
                                                            </i>
                                                        </span>
                                                    </td>
                                            </tr>

                                        )
                                    }
                                })
                            }
                            </tbody>
                        </table>  
                    :
                        null                    
                } 
            </SRLWrapper>                               
    )
}

export default UplmPlus2PendingList;