import React, { useEffect, useState, Fragment, useContext } from 'react';
import axios from 'axios';
import { LoginContext } from '../../hooks/context/LoginContext';
import $ from 'jquery';
import jQuery from 'jquery';
import DataTable from 'datatables.net';
import Spinner from 'react-bootstrap/Spinner'
import ReactDOM from 'react-dom';
import Cookies from 'universal-cookie';
import MiSelect from '../../componentes/formulario/MiSelect';
import BaseSelect from "react-select";
import swal from 'sweetalert';
import DatePicker from 'rsuite/DatePicker';

import { alertService } from '../../services/alertservice';



import { SRLWrapper } from 'simple-react-lightbox';

import { getApiUrl, getPantDocFromNivel, getDecodedCookie, getDatePickerDaysName } from '../../helpers/helperApp';

require('datatables.net-dt');
require('datatables.net-responsive');
require('datatables.net-buttons');
require('datatables.net-bs4');

require('datatables.net/js/jquery.dataTables');
require('datatables.net-buttons/js/dataTables.buttons');
require('datatables.net-buttons/js/buttons.colVis');
require('datatables.net-buttons/js/buttons.flash');
require('datatables.net-buttons/js/buttons.html5');
require('datatables.net-buttons/js/buttons.print');
require('datatables.net-select');
require('datatables.mark.js/build/templates/datatables.mark');
require('datatables.mark.js/dist/datatables.mark.es6');
require('datatables.net-rowreorder-bs4/js/rowReorder.bootstrap4');
require('datatables.net-rowreorder');

$.DataTable = DataTable;

let date = new Date();
let mesHoy = date.getMonth() + 1;
let h = date.getDate();
let hoy = (h < 10 ? h = '0'+h : h = h )+'/'+(mesHoy < 10 ? mesHoy = '0'+mesHoy : mesHoy = mesHoy )+'/'+date.getFullYear();

var botones = [
    // {
    //     extend: 'pageLength',
    //     text: `<i style='font-size: 20px;' class='fal fa-list-ol'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     // titleAttr: 'Ver número de registros'
    // },
    // {
    //     extend: 'copy',
    //     text: `<i style='font-size: 20px;' class='fal fa-copy'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     // titleAttr: 'Copiar al portapapeles',
    //     exportOptions: {
    //         columns: ':visible'
    //     }
    // }, 
    // {
    //     extend: 'csv',
    //     filename: hoy,
    //     text: `<i style='font-size: 20px;' class='fal fa-file-csv'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     title: hoy,
    //     // titleAttr: 'Exportar a csv',
    //     exportOptions: {
    //         columns: ':visible'
    //     }

    // },
    {
        extend: 'excel',
        filename: hoy,
        text: `<i style='font-size: 20px;' class='fal fa-file-excel'></i>`,
        className: 'btn btn-flat mr-1 btn-outline-secondary',
        title: hoy,
        // titleAttr: 'Exportar a excel',
        exportOptions: {
            columns: ':visible'
        }
    }, 
    {
        extend: 'pdf',
        filename: hoy,
        text: `<i style='font-size: 20px;' class='fal fa-file-pdf'></i>`,
        className: 'btn btn-flat mr-1 btn-outline-secondary',
        orientation: 'landscape', 
        title: hoy,
        // titleAttr: 'Exportar a pdf',
        exportOptions: {
            columns: ':visible'
        }
    }, 
    // {
    //     extend: 'print',
    //     filename: hoy,
    //     text: `<i style='font-size: 20px;' class='fal fa-print'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     title: hoy,
    //     // titleAttr: 'Imprimir',
    //     customize: function ( win ) {
    //         $(win.document.body)
    //             .css( 'font-size', '22pt' );

    //         $(win.document.body).find( 'table' )
    //             .addClass( 'compact' )
    //             .css( 'font-size', '14pt' );
    //     },
    //     exportOptions: {
    //         modifier: {
    //             selected: null
    //         }
    //     },
    //     exportOptions: {
    //         columns: ':visible'
    //     }
    // },
    // {
    //     extend: 'colvis',
    //     text: `<i style='font-size: 20px;' class='fad fa-line-columns'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    // }
];

function MiDataTablePlanificador(props){


    const cookies = new Cookies();

    const { apiKey, confApp } = useContext(LoginContext);  

    // ***** props *****
    const { id, data, saveData, columnas, formulario, optionselectubi, optionselectage, optionselectest, selectubi, selectage, selectest, fecini, fecprev, getDocPlanEnCurso, confPantalla } = props;
    let { buttons, ordering, order, searching, paging, select, saveLoadingTable, loadingTable, columnactions, traduction, linkColumns, functionClickTd, pagelength, columnsFunctionClickTd, idFiltro, rowreorder, functionAfterReorder, saveLoading } = props;
    
    useEffect(()=>{
        
        const accionesMiDataTable = columnas.find(column => column["title"] === columnactions);

        // Si ya existe la columna de acciones la eliminamos ya que sino da error cuando seleccionas un reg, vas a otra pantalla, vuelves y seleccionas otro reg.        
        if( accionesMiDataTable !== undefined ){
            columnas.splice( (columnas.length-1), 1 );
        }

        // Añadimos los enlaces a los td
        if( linkColumns != undefined ){
            for( let i = 0; i < columnas.length; i++ ){
                columnas[i].createdCell = (td, cellData, rowData, row, col) => {

                    if( linkColumns[i] != undefined ){
                        // Aplicamos el estilo a las celdas añadiendo las clases recibidas en el sql en el key 'style'
                        if ('style' in rowData){
                            $(td).addClass(rowData["style"]);
                        }
                        //Creamos los enlaces
                        // Si el enlace es a la pantalla de documentos obtenemos la pantalla que le corresponde según su id
                        if( linkColumns[i].link == "/documentos" ){
                            linkColumns[i].link = getPantDocFromNivel(getDecodedCookie('nivel'));
                        }
                        if( linkColumns[i].data.includes("/") && linkColumns[i].link != "" ){
                            const links = linkColumns[i].data.split("/");
                            $(td).html("<a href='"+window.location.origin+linkColumns[i].link+"/"+rowData[links[0]]+"/"+rowData[links[1]]+"' >"+cellData+"</a>");
                        }else if( linkColumns[i].link != "" ){
                            $(td).html("<a href='"+window.location.origin+linkColumns[i].link+"/"+rowData[linkColumns[i].data]+"' >"+cellData+"</a>");
                        }
                    } 
                    // Si la celda contiene la cadena '/assets/' y rowData hay una key foto asumimos que es una foto
                    if( 'foto' in rowData || 'img' in rowData ){
                        if( cellData ){
                            if( cellData.includes('/assets/')  ){
                                $(td).html("<img src='"+getApiUrl(window.location.origin)+cellData+"' style='width: 100px !important; cursor: pointer;' />");
                            }
                        }else{
                            $(td).html("<span></span>");
                        }
                    }                   
                    if( functionClickTd ){
                        if( columnsFunctionClickTd ){
                            if( columnsFunctionClickTd.includes(col) ){
                                $(td).on('click', function (e) {
                                    e.preventDefault();
                                    functionClickTd(e, rowData);
                                });
                            }
                        }else{
                            $(td).on('click', function (e) {
                                e.preventDefault();
                                functionClickTd(e, rowData);
                            });
                        }                        
                    }                    
                }
            }
        }else{
            for( let i = 0; i < columnas.length; i++ ){
  
                columnas[i].createdCell = (td, cellData, rowData, row, col) => {

                        // Aplicamos el estilo a las celdas añadiendo las clases recibidas en el sql en el key 'style'
                        if ('style' in rowData){
                            $(td).addClass(rowData["style"]);
                        }
                        // Si la celda contiene la cadena '/assets/' y rowData hay una key foto asumimos que es una foto
                        if( 'foto' in rowData || 'img' in rowData ){
                            if( cellData ){
                                if( cellData.includes('/assets/')  ){

                                    let templateFoto = `
                                    <div>                                   
                                            <div class="containerImgTablePending">
                                                <img class="imgUplmTablePending" src=${getApiUrl(window.location.origin)+cellData} />
                                            </div>                                                                                                                                        
                                    </div>
                                    `;
                                    $(td).html(templateFoto);
                                    // $(td).html("<img src='"+getApiUrl(window.location.origin)+cellData+"' style='width: 100px !important; cursor: pointer' />");
                                }
                            }else{
                                $(td).html("<span></span>");
                            }
                        }
                        if( functionClickTd ){
                            if( columnsFunctionClickTd ){
                                if( columnsFunctionClickTd.includes(col) ){
                                    $(td).on('click', function (e) {
                                        e.preventDefault();
                                        functionClickTd(e, rowData);
                                    });
                                }
                            }else{
                                $(td).on('click', function (e) {
                                    e.preventDefault();
                                    functionClickTd(e, rowData);
                                });
                            }                        
                        }                    
                }
            }
        }

        buttons === undefined || buttons === true || buttons === "true" ? buttons = 'Bfrtip' : buttons = 'frtip';
        ordering === undefined ? ordering = true : ordering = ordering;
        order === undefined ? order = [[0,"asc"]] : order = order;
        searching === undefined ? searching = true : searching = searching;
        paging === undefined ? paging = true : paging = paging;
        pagelength === undefined ? pagelength = traduction.defaultpagelength : pagelength = pagelength;
        select === undefined ? select = false : select = select;

        columnas.map((columna, i) => {
            if( i == 1 ){
                // Fecha inicio
                columna.createdCell = (td, cellData, rowData, row, col) => {  

                    let templateNecesario =  ReactDOM.render( 
                        <div className="row">
                            <div className="col-10" >
                                <DatePicker
                                  id={fecini.id}
                                  name={fecini.name}
                                  placeholder={' '}
                                  format={"yyyy-MM-dd"}
                                  defaultValue={ rowData.fecinidata == '0000-00-00T00:00' || rowData.fecinidata == '' ? null : new Date(rowData.fecinidata) }                                  
                                  onOk={(value, event) => handleChangeFec(value, rowData, "fec1")}                                
                                  readOnly={ fecini.readonly }
                                  className={''}
                                  disabled={ rowData.idest == "11" || rowData.idest == "8" || rowData.idest == "0" ? false : true } 
                                  // showTime
                                  block={true}
                                  weekStart={1}
                                  locale={getDatePickerDaysName()}
                                  placement={'auto'}
                                />   
                            </div>
                            <div className={"col-1 mt-2 noDisplay spinFec1_"+rowData.id_linea}>
                                <i className="fal fa-spinner fa-spin"></i>  
                            </div>
                            <div className={"col-1 noDisplay checkFec1_"+rowData.id_linea} style={{padding: 0, color: "#28a745"}}>
                                <i className="fal fa-check fa-2x"></i>  
                            </div>
                            <div className={"col-1 mt-1 noDisplay errorFec1_"+rowData.id_linea} style={{padding: 0, color: "#dc3545"}}>
                                <i className="fal fa-times fa-2x"></i>  
                            </div>
                        </div>                              
                    ,td);
                    $(td).addClass(rowData.style);
                    $(td).html(templateNecesario)
                } 
            }
            if( i == 2 ){
                // Fecha prevision
                columna.createdCell = (td, cellData, rowData, row, col) => {  

                    let templateNecesario =  ReactDOM.render( 
                        <div className="row">
                            <div className="col-10" >
                                <DatePicker
                                  id={fecprev.id}
                                  name={fecprev.name}
                                  placeholder={' '}
                                  format={"yyyy-MM-dd"}
                                  defaultValue={ rowData.fecprevisiondata == '0000-00-00T00:00' || rowData.fecprevisiondata == '' ? null : new Date(rowData.fecprevisiondata) }                                  
                                  onOk={(value, event) => handleChangeFec(value, rowData, "fec2")}                                
                                  readOnly={ fecprev.readonly }
                                  className={''}
                                  disabled={ rowData.idest == "11" || rowData.idest == "8" || rowData.idest == "0" ? false : true } 
                                  // showTime
                                  block={true}
                                  weekStart={1}
                                  locale={getDatePickerDaysName()}
                                  placement={'auto'}
                                />   
                            </div>
                            <div className={"col-1 mt-2 noDisplay spinFec2_"+rowData.id_linea}>
                                <i className="fal fa-spinner fa-spin"></i>  
                            </div>
                            <div className={"col-1 noDisplay checkFec2_"+rowData.id_linea} style={{padding: 0, color: "#28a745"}}>
                                <i className="fal fa-check fa-2x"></i>  
                            </div>
                            <div className={"col-1 mt-1 noDisplay errorFec2_"+rowData.id_linea} style={{padding: 0, color: "#dc3545"}}>
                                <i className="fal fa-times fa-2x"></i>  
                            </div>
                        </div>                              
                    ,td);
                    $(td).addClass(rowData.style);
                    $(td).html(templateNecesario)
                } 
            }
            if( i == 5 ){
                // Agente
                columna.createdCell = (td, cellData, rowData, row, col) => {  
                    let templateNecesario =  ReactDOM.render( 
                        <div className="row">
                            <div className="col-10" >
                                <BaseSelect
                                        className={rowData.id} 
                                        options={optionselectage}
                                        name={selectage.name}             
                                        id={row}
                                        placeholder={ selectage.placeholder } 
                                        // value={options.length > 0 ? options.filter(option => option.value == rowData.ubicacionactual) : null}
                                        defaultValue={ optionselectage.length > 0 ? optionselectage.filter(option => option.value == rowData.idage) : null } 
                                        onChange={ e => handleChangeSelect(e, rowData, "idage2") }
                                        required={selectage.required}
                                        isDisabled={ rowData.idest == "11" || rowData.idest == "8" || rowData.idest == "0" ? false : true}
                                        isClearable={selectage.isclearable}
                                        isSearchable={selectage.issearchable}
                                        isMulti={false}
                                        isLoading={false}
                                        noOptionsMessage={ ({ inputValue: string }) => confApp.config.trad.components.select.noresult.title }
                                        menuPlacement={"auto"}
                                        menuPortalTarget={document.body}                                              
                                        styles={{
                                            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                            menu: (provided) => ({ ...provided, zIndex: 9999 })
                                        }}                           
                                />  
                            </div>
                            <div className={"col-1 mt-2 noDisplay spin_"+rowData.id_linea+"_idage2"}>
                                <i className="fal fa-spinner fa-spin"></i>  
                            </div>
                            <div className={"col-1 noDisplay check_"+rowData.id_linea+"_idage2"} style={{padding: 0, color: "#28a745"}}>
                                <i className="fal fa-check fa-2x"></i>  
                            </div>
                            <div className={"col-1 mt-1 noDisplay error_"+rowData.id_linea+"_idage2"} style={{padding: 0, color: "#dc3545"}}>
                                <i className="fal fa-times fa-2x"></i>  
                            </div>
                        </div>                              
                    ,td);
                    $(td).addClass(rowData.style);
                    $(td).html(templateNecesario)                                         
                }
            }  
            // if( i == 6 ){
            //     // Ubicación
            //     columna.createdCell = (td, cellData, rowData, row, col) => {  
            //         let templateNecesario =  ReactDOM.render( 
            //             <div className="row">
            //                 <div className="col-10" >
            //                     <BaseSelect
            //                             className={rowData.id} 
            //                             options={optionselectubi}
            //                             name={selectubi.name}             
            //                             id={row}
            //                             placeholder={ selectubi.placeholder } 
            //                             // value={options.length > 0 ? options.filter(option => option.value == rowData.ubicacionactual) : null}
            //                             defaultValue={ optionselectubi.length > 0 ? optionselectubi.filter(option => option.value == rowData.ubicacionactual) : null } 
            //                             onChange={ e => handleChangeSelect(e, rowData, "idubi") }
            //                             required={selectubi.required}
            //                             isDisabled={ rowData.idest == "11" || rowData.idest == "8" || rowData.idest == "0" ? false : true}
            //                             isClearable={selectubi.isclearable}
            //                             isSearchable={selectubi.issearchable}
            //                             isMulti={false}
            //                             isLoading={false}
            //                             noOptionsMessage={ ({ inputValue: string }) => confApp.config.trad.components.select.noresult.title }
            //                             menuPlacement={"auto"}
            //                             menuPortalTarget={document.body}                                              
            //                             styles={{
            //                                 menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
            //                                 menu: (provided) => ({ ...provided, zIndex: 9999 })
            //                             }}                           
            //                     />  
            //                 </div>
            //                 <div className={"col-1 mt-2 noDisplay spin_"+rowData.id_linea+"_idubi"}>
            //                     <i className="fal fa-spinner fa-spin"></i>  
            //                 </div>
            //                 <div className={"col-1 noDisplay check_"+rowData.id_linea+"_idubi"} style={{padding: 0, color: "#28a745"}}>
            //                     <i className="fal fa-check fa-2x"></i>  
            //                 </div>
            //                 <div className={"col-1 mt-1 noDisplay error_"+rowData.id_linea+"_idubi"} style={{padding: 0, color: "#dc3545"}}>
            //                     <i className="fal fa-times fa-2x"></i>  
            //                 </div>
            //             </div>                              
            //         ,td);
            //         $(td).addClass(rowData.style);
            //         $(td).html(templateNecesario)                                         
            //     }
            // }
            if( i == 6){
                // Estado
                columna.createdCell = (td, cellData, rowData, row, col) => { 
                    let templateNecesario =  ReactDOM.render( 
                        <div className="row">
                            <div className="col-10" >
                                <BaseSelect
                                        className={rowData.id} 
                                        options={optionselectest}
                                        name={selectest.name}             
                                        id={row}
                                        placeholder={ selectest.placeholder } 
                                        // value={options.length > 0 ? options.filter(option => option.value == rowData.ubicacionactual) : null}
                                        defaultValue={ optionselectest.length > 0 ? optionselectest.filter(option => option.value == rowData.idest) : null } 
                                        onChange={ e => handleChangeSelect(e, rowData, "idest") }
                                        required={selectest.required}
                                        isDisabled={rowData.idest == "11" || rowData.idest == "8" || rowData.idest == "0" ? false : true }  
                                        isClearable={selectest.isclearable}
                                        isSearchable={selectest.issearchable}
                                        isMulti={false}
                                        isLoading={false}
                                        noOptionsMessage={ ({ inputValue: string }) => confApp.config.trad.components.select.noresult.title }
                                        menuPlacement={"auto"}
                                        menuPortalTarget={document.body}                                              
                                        styles={{
                                            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                                            menu: (provided) => ({ ...provided, zIndex: 9999 })
                                        }}                                                                 
                                />  
                            </div>
                            <div className={"col-1 mt-2 noDisplay spin_"+rowData.id_linea+"_idest"}>
                                <i className="fal fa-spinner fa-spin"></i>  
                            </div>
                            <div className={"col-1 noDisplay check_"+rowData.id_linea+"_idest"} style={{padding: 0, color: "#28a745"}}>
                                <i className="fal fa-check fa-2x"></i>  
                            </div>
                            <div className={"col-1 mt-1 noDisplay error_"+rowData.id_linea+"_idest"} style={{padding: 0, color: "#dc3545"}}>
                                <i className="fal fa-times fa-2x"></i>  
                            </div>
                        </div>                              
                    ,td);
                    $(td).addClass(rowData.style);
                    $(td).html(templateNecesario)                                         
                }                    
            }
            if( i == 7){
                // Acciones
                columna.createdCell = (td, cellData, rowData, row, col) => {                    
                    let templateNecesario =  ReactDOM.render(
                        rowData.idest == "8"
                        ?
                            <div className={"text-center"}>
                                <span className="btn btn-outline-danger"><i className="fal fa-trash-alt" onClick={e => {deleteLinea(e, rowData)}}></i></span>
                            </div>                            
                        :
                        (rowData.idest == "9" || rowData.idest == "12") && rowData.id_trab
                        ?
                            <div className='text-center'>
                                <a href={getPantDocFromNivel(getDecodedCookie('nivel'))+"/"+rowData.id_trab}>
                                    <span className='btn btn-outline-primary'><i className='fal fa-eye'></i></span>
                                </a>
                            </div>
                        :
                            null
                    ,td);
                    $(td).addClass(rowData.style);
                    $(td).html(templateNecesario)                                        
                }
                    
            }
        })


        if( data ){
            if( data.length > 0 ){
                if ( $.fn.DataTable.isDataTable("."+id) ) {
                    let oTable = $("."+id).DataTable();
                    oTable.clear( );
                    for (let i = 0; i < data.length; i++){
                        oTable.row.add(data[i]);      
                    }
                    oTable.draw();               
                }else{
                    $("."+id).DataTable({
                        mark: true,
                        rowId: function(a) {
                            return '#row-' + a.id_linea;},
                        dom: buttons,
                        data: data,
                        columns: columnas,
                        ordering: ordering,
                        order: order,
                        searching: searching,
                        paging: paging,
                        select: select,
                        buttons: [botones],
                        // fixedHeader: true,
                        // scrollX: true,
                        responsive: false, 
                        autoWidth: false,
                        retrieve: true,
                        pageLength: parseInt(pagelength),
                        lengthMenu: [
                            [ 10, 25, 50, 100 ],
                            [ '10', '25', '50', '100' ]
                        ],
                        rowReorder: rowreorder ? true : false,
                        deferRender: true,
                        language: {
                            "sProcessing":    traduction.sprocessing.title,
                            "sLengthMenu":    traduction.slengthmenu.title,
                            "sZeroRecords":   traduction.szerorecords.title,
                            "sEmptyTable":    traduction.semptytable.title,
                            "sInfo":          traduction.sinfo.title,
                            "sInfoEmpty":     traduction.sinfoempty.title,
                            "sInfoFiltered":  traduction.sinfofiltered.title,
                            "sInfoPostFix":   traduction.sinfopostfix.title,
                            "sSearch":        traduction.ssearch.title,
                            "sUrl":           traduction.surl.title,
                            "sInfoThousands":  traduction.sinfothousands.title,
                            "sLoadingRecords": traduction.sloadingrecords.title,
                            "oPaginate": {
                                "sFirst":    traduction.sfirst.title,
                                "sLast":    traduction.slast.title,
                                "sNext":    traduction.snext.title,
                                "sPrevious": traduction.sprevious.title
                            },
                            "oAria": {
                                "sSortAscending":  traduction.ssortascending.title,
                                "sSortDescending": traduction.ssortdescending.title
                            },
                            select: {
                                rows: traduction.rows.title
                            },
                            buttons: {
                                copyTitle: traduction.copytitle.title,
                                copyKeys: traduction.copykeys.title,
                                copySuccess: {
                                    _: traduction.copysuccess.many.title,
                                    1: traduction.copysuccess.one.title
                                },
                            }        
                        },
                        rowCallback : function (row, data, index){ 
                            // Si td campo incluye un # asumimos que es un color con lo que se lo ponemos de fondo
                            for (let item of row.children) {
                                if( item.outerText.includes("#") ){
                                    item.bgColor = item.outerText;
                                    item.innerText = "";
                                }
                            }
                        }
                    })
                }  
                // saveLoadingTable(false);       
            }else{
                if ( $.fn.DataTable.isDataTable("."+id) ) {
                    let oTable = $("."+id).DataTable();
                    oTable.clear();
                    oTable.draw();               
                }{               
                    $("."+id).DataTable({
                        mark: true,
                        rowId: function(a) {
                            return '#row-' + a.id_linea;},
                        dom: buttons,
                        data: null,
                        columns: columnas,
                        ordering: ordering,
                        order: order,
                        searching: searching,
                        paging: paging,
                        select: select,
                        buttons: [botones],
                        // fixedHeader: true,
                        // scrollX: true,
                        responsive: false, 
                        autoWidth: false,
                        retrieve: true,
                        pageLength: parseInt(pagelength),
                        lengthMenu: [
                            [ 10, 25, 50, 100 ],
                            [ '10', '25', '50', '100' ]
                        ],
                        rowReorder: rowreorder ? true : false,
                        deferRender: true,
                        language: {
                            "sProcessing":    traduction.sprocessing.title,
                            "sLengthMenu":    traduction.slengthmenu.title,
                            "sZeroRecords":   traduction.szerorecords.title,
                            "sEmptyTable":    traduction.semptytable.title,
                            "sInfo":          traduction.sinfo.title,
                            "sInfoEmpty":     traduction.sinfoempty.title,
                            "sInfoFiltered":  traduction.sinfofiltered.title,
                            "sInfoPostFix":   traduction.sinfopostfix.title,
                            "sSearch":        traduction.ssearch.title,
                            "sUrl":           traduction.surl.title,
                            "sInfoThousands":  traduction.sinfothousands.title,
                            "sLoadingRecords": traduction.sloadingrecords.title,
                            "oPaginate": {
                                "sFirst":    traduction.sfirst.title,
                                "sLast":    traduction.slast.title,
                                "sNext":    traduction.snext.title,
                                "sPrevious": traduction.sprevious.title
                            },
                            "oAria": {
                                "sSortAscending":  traduction.ssortascending.title,
                                "sSortDescending": traduction.ssortdescending.title
                            },
                            select: {
                                rows: traduction.rows.title
                            },
                            buttons: {
                                copyTitle: traduction.copytitle.title,
                                copyKeys: traduction.copykeys.title,
                                copySuccess: {
                                    _: traduction.copysuccess.many.title,
                                    1: traduction.copysuccess.one.title
                                },
                            }         
                        },
                        rowCallback : function (row, data, index){
                            // Si td campo incluye un # asumimos que es un color con lo que se lo ponemos de fondo
                            for (let item of row.children) {
                                if( item.outerText.includes("#") ){
                                    item.bgColor = item.outerText;
                                    item.innerText = "";
                                }
                            }


                        }
                    })
                }
                // saveLoadingTable(false);
            }
        }else{
            if ( $.fn.DataTable.isDataTable("."+id) ) {
                let oTable = $("."+id).DataTable();
                oTable.clear();
                oTable.draw();               
            }{               
                $("."+id).DataTable({
                    mark: true,
                    rowId: function(a) {
                        return '#row-' + a.id_linea;},
                    dom: buttons,
                    data: null,
                    columns: columnas,
                    ordering: ordering,
                    order: order,
                    searching: searching,
                    paging: paging,
                    select: select,
                    buttons: [botones],
                    // fixedHeader: true,
                    // scrollX: true,
                    responsive: false, 
                    autoWidth: false,
                    retrieve: true,
                    pageLength: parseInt(pagelength),
                    lengthMenu: [
                        [ 10, 25, 50, 100 ],
                        [ '10', '25', '50', '100' ]
                    ],
                    rowReorder: rowreorder ? true : false,
                    deferRender: true,
                    language: {
                        "sProcessing":    traduction.sprocessing.title,
                        "sLengthMenu":    traduction.slengthmenu.title,
                        "sZeroRecords":   traduction.szerorecords.title,
                        "sEmptyTable":    traduction.semptytable.title,
                        "sInfo":          traduction.sinfo.title,
                        "sInfoEmpty":     traduction.sinfoempty.title,
                        "sInfoFiltered":  traduction.sinfofiltered.title,
                        "sInfoPostFix":   traduction.sinfopostfix.title,
                        "sSearch":        traduction.ssearch.title,
                        "sUrl":           traduction.surl.title,
                        "sInfoThousands":  traduction.sinfothousands.title,
                        "sLoadingRecords": traduction.sloadingrecords.title,
                        "oPaginate": {
                            "sFirst":    traduction.sfirst.title,
                            "sLast":    traduction.slast.title,
                            "sNext":    traduction.snext.title,
                            "sPrevious": traduction.sprevious.title
                        },
                        "oAria": {
                            "sSortAscending":  traduction.ssortascending.title,
                            "sSortDescending": traduction.ssortdescending.title
                        },
                        select: {
                            rows: traduction.rows.title
                        },
                        buttons: {
                            copyTitle: traduction.copytitle.title,
                            copyKeys: traduction.copykeys.title,
                            copySuccess: {
                                _: traduction.copysuccess.many.title,
                                1: traduction.copysuccess.one.title
                            },
                        }       
                    },
                    rowCallback : function (row, data, index){
                        // Si algún td incluye un # asumimos que es un color con lo que se lo ponemos de fondo
                        for (let item of row.children) {
                            if( item.outerText.includes("#") ){
                                item.bgColor = item.outerText;
                                item.innerText = "";
                            }
                        }                        
                    }
                })
            }
            // saveLoadingTable(false);
            
        }
        
        if( saveLoadingTable ){
            saveLoadingTable(false); 
        }

        
        // Código para llamar a una función tras reordenar las filas
        if( functionAfterReorder != null  ){
            let tabla = $('#'+id).DataTable();
            let rows = tabla.rows().data();
            tabla.on( 'row-reorder', function ( e, diff, edit ) {
                    functionAfterReorder( e, diff, edit);         
            } );
        }

    }, [data, formulario.id])

    const deleteLinea = async (e,rowData) =>{
        e.stopPropagation();
        swal({
            title: "",
            text: confApp.config.trad.logic.delete.swal.title,
            icon: "warning",
            buttons: {
                cancel : {text: confApp.config.trad.logic.delete.swal.button.cancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                confirm : {text: confApp.config.trad.logic.delete.swal.button.confirm.title, className:'btn btn-flat mr-1 btn-outline-danger btnSwalDelete'}
            } 
        }).then(confirmacion => {
            if(confirmacion){
                borrarLinea(rowData.id_linea, rowData.posicion, rowData.id)                
            }
        })
    }

    const borrarLinea = async(idLinea, posicion, idDoc) => {
        saveLoading(true);
        const data = new FormData();      
        data.append("validacion", apiKey());
        data.append("idlinea", idLinea);
        data.append("posicion", posicion);
        data.append("iddoc", idDoc);        
        const respuesta = await axios.post(getApiUrl(window.location.origin)+confPantalla.planificador.controles.datatableedi.datadeletelinea.delete, data);
        if(typeof respuesta.data == "string"){
            // Error borrando
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.planificador.controles.datatableedi.datadeletelinea.deleteerror.title}`, {autoClose: true}); 
        }else{
            // Recargamos el documento y la tabla del planificador
            await getDocPlanEnCurso(idDoc);
            // Borrado
            alertService.success(`<i class="fal fa-check-circle mr-1"></i>${confPantalla.planificador.controles.datatableedi.datadeletelinea.deletesuccess.title}`, {autoClose: true}); 
        }
        saveLoading(false);
    }


    const handleChangeFec = async (val, rowData, campo) => {

        let value;
        const anho = val.getFullYear()
        const mes = val.getMonth()+1 < 10 ? '0'+(val.getMonth()+1) : val.getMonth()+1;
        const dia = val.getDate() < 10 ? '0'+val.getDate() : val.getDate();
        const horas = val.getHours() < 10 ? '0' + val.getHours() : val.getHours(); 
        const minutos = val.getMinutes() < 10 ? '0' + val.getMinutes() : val.getMinutes();
        value = `${anho}-${mes}-${dia}T${horas}:${minutos}`;

        let idLinea = rowData.id_linea;

        const aux = campo == "fec1" ? "Fec1" : "Fec2";

        let spinner = document.querySelector(".spin"+aux+"_"+idLinea);        
        let check = document.querySelector(".check"+aux+"_"+idLinea);        
        let error = document.querySelector(".error"+aux+"_"+idLinea);        

        if( spinner ){
            spinner.classList.toggle('noDisplay');
        }
        if( check ){
            check.classList.add('noDisplay');
        }
        if( error ){
            error.classList.add('noDisplay');
        }

        const dataForm = new FormData();      
        dataForm.append("validacion", apiKey());
        dataForm.append("idlinea", idLinea);
        dataForm.append("campo", campo);
        dataForm.append("value", value);
        const respuesta = await axios.post(getApiUrl(window.location.origin)+"/planificador/updateCampo",dataForm);
        
        spinner.classList.toggle('noDisplay');

        if(respuesta.data == idLinea){
            if( check ){
                check.classList.toggle('noDisplay');
            }
            // Recargamos el documento y la tabla del planificador
            await getDocPlanEnCurso(rowData.id);
        }else if(typeof respuesta.data == "string"){    
            if( error ){
                error.classList.toggle('noDisplay');
            }
        }

    }
    

    const handleChangeSelect = async (e, rowData, campo) => {
        let idLinea = rowData.id_linea;
        let value;
        if( e ){
            value = e.value;
        }else{
            value = 0;
        }

        let spinner = document.querySelector(".spin_"+idLinea+"_"+campo);        
        let check = document.querySelector(".check_"+idLinea+"_"+campo);        
        let error = document.querySelector(".error_"+idLinea+"_"+campo);        

        if( spinner ){
            spinner.classList.toggle('noDisplay');
        }
        if( check ){
            check.classList.add('noDisplay');
        }
        if( error ){
            error.classList.add('noDisplay');
        }

        const dataForm = new FormData();      
        dataForm.append("validacion", apiKey());
        dataForm.append("idlinea", idLinea);
        dataForm.append("campo", campo);
        dataForm.append("value", value);
        dataForm.append("idori", rowData.id_docu);
        const respuesta = await axios.post(getApiUrl(window.location.origin)+"/planificador/updateCampo",dataForm);
        
        spinner.classList.toggle('noDisplay');

        if(respuesta.data == idLinea){
            if( check ){
                check.classList.toggle('noDisplay');
            }
            // Recargamos el documento y la tabla del planificador
            await getDocPlanEnCurso(rowData.id);
        }else if(typeof respuesta.data == "string"){    
            if( error ){
                error.classList.toggle('noDisplay');
            }
        }

    }


    // Código para evitar los acentos en las búsquedas
    function removeAccents ( data ) {
        if ( data.normalize ) {
            // Use I18n API if avaiable to split characters and accents, then remove
            // the accents wholesale. Note that we use the original data as well as
            // the new to allow for searching of either form.
            return data +' '+ data
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
        }
     
        return data;
    }
    var searchType = jQuery.fn.DataTable.ext.type.search;
 
    searchType.string = function ( data ) {
        return ! data ?
            '' :
            typeof data === 'string' ?
                removeAccents( data ) :
                data;
    };
    
    searchType.html = function ( data ) {
        return ! data ?
            '' :
            typeof data === 'string' ?
                removeAccents( data.replace( /<.*?>/g, '' ) ) :
                data;
    };
    // FIN: Código para evitar los acentos en las búsquedas




    return(
        <Fragment>
            {   loadingTable ? 
                    <div style={{height:50, marginTop:5, marginBottom:10, textAlign: "center"}} >
                        <Spinner animation="border" />
                    </div>
                :
                null
            }
            {
                data == null
                ?
                    null
                :
                    <div style={{overflowX: "auto"}}>
                        <table id={id} className={id+" table table-sm table-bordered table-striped table-hover dataTable"} style={{width:"99%"}} ></table>
                    </div>  
            }
            
        </Fragment>
    )
}

export default MiDataTablePlanificador;